<template>
    <b-modal
        id="modal-add-filter-orders"
        bg-variant="white"
        backdrop size="lg"
        shadow
        right
        centered
    >
        <template #modal-header>
            <div class="d-flex align-items-center justify-content-start w-100 title-modal">
                <span class="modal-title">
                    {{$t('FilterDays.Filter')}}
                </span>
            </div>
            <button
                type="button"
                aria-label="Close"
                class="close close-mobile"
                @click="$bvModal.hide('modal-add-filter-orders')"
            >×</button>
        </template>
        <div class="div-selected-items">
            <b-col
                cols="12"
                v-if="(filterPeriod
                        && ((selectedPeriodsFilters != 'Custom' && selectedPeriodsFilters != '')
                        || (selectedPeriodsFilters == 'Custom' && startPeriod != '' && endPeriod != '')))
                    || (filterStatus && selectedStatusFilters.length > 0)
                    || (filterWorkcenter && selectedWorkcentersFilters.length > 0)
                    || (filterResource && selectedResourcesFilters.length > 0)
                "
            >
                <span class="modal-add-text">{{ $t('Operator.SelectedFilters') }}</span>
            </b-col>

            <VueSlickCarousel
                v-bind="settings"
                :key="`carousel-modal-filters-orders-${reRenderCarousel}`"
                class="filtros-area"
                v-if="!filterPeriod"
            >x
                <b-button
                    class="selected-filter"
                    v-for="selected in (filterStatus
                                            ? selectedStatusFilters
                                            : filterWorkcenter
                                                ? selectedWorkcentersFilters
                                                : selectedResourcesFilters
                    )"
                    :key="`selected-filter-${selected}`"
                >
                    <div>
                        <span>{{ selected.name }}</span>
                        <remove-icon
                            class="cursor-pointer"
                            @click="managerSelectedFilters(selected.type, selected)"
                        />
                    </div>
                </b-button>
            </VueSlickCarousel>

            <VueSlickCarousel
                v-bind="settings"
                :key="`carousel-modal-filters-orders-2-${reRenderCarousel}`"
                v-if="filterPeriod && selectedPeriodsFilters != ''"
                class="filtros-area"
            >

                <b-button
                    class="selected-filter"
                    v-if="selectedPeriodsFilters == 'Custom' && endPeriod != '' && startPeriod != ''"
                >
                    <div>
                        <span>{{ `${formatDate(startPeriod)} ${$t('Operator.Until')} ${formatDate(endPeriod)}` }}</span>
                        <remove-icon
                            class="cursor-pointer"
                            @click="managerSelectedFilters('remove-period', selectedPeriodsFilters)"
                        />
                    </div>
                </b-button>
                <b-button
                    class="selected-filter"
                    v-if="selectedPeriodsFilters != 'Custom'"
                >
                    <div>
                        <span>
                            {{
                                selectedPeriodsFilters === 'AllPeriod'
                                ? $t('Operator.AllPeriod')
                                : selectedPeriodsFilters === '1Day'
                                ? ($t('Operator.Horizon') + $t('Operator.1Day'))
                                : selectedPeriodsFilters === '5Days'
                                ? ($t('Operator.Horizon') + ' ' + $t('Operator.5Days'))
                                : selectedPeriodsFilters === '15Days'
                                ? ($t('Operator.Horizon') + ' ' + $t('Operator.15Days')): ''
                            }}
                        </span>
                        <remove-icon
                            class="cursor-pointer"
                            @click="managerSelectedFilters('remove-period', selectedPeriodsFilters)"
                        />
                    </div>
                </b-button>
            </VueSlickCarousel>
        </div>

        <b-row class="m-0 content-modal-filter-orders">
            <b-col cols="12" sm="4" md="4" lg="4" xl="4">
                <div class="div-type-filter">
                    <span class="modal-add-text">{{ $t('Operator.Type') }}</span>

                    <div class="div-type" @click="toggleFilter('period')">
                        <div class="filter-type" :class="{ 'active': filterPeriod }">
                            <period />
                        </div>
                        <span>{{ $t('Operator.Period') }}</span>
                    </div>

                    <div class="div-type mt-2" @click="toggleFilter('status')">
                        <div class="filter-type" :class="{ 'active': filterStatus }">
                            <status-icon />
                        </div>
                        <span>Status</span>
                    </div>

                    <div class="div-type mt-2" @click="toggleFilter('workcenters')" v-if="!resource_id">
                        <div class="filter-type" :class="{ 'active': filterWorkcenter }">
                            <workcenter-icon />
                        </div>
                        <span>{{ $t('Operator.Workcenters') }}</span>
                    </div>
                    <div class="div-type mt-2" @click="toggleFilter('resources')" v-if="!resource_id">
                        <div class="filter-type" :class="{ 'active': filterResource }">
                            <resources-icon />
                        </div>
                        <span>{{ $t('Operator.Resources') }}</span>
                    </div>
                </div>

                <div class="div-type-filter-mobile">
                    <b-form inline>
                        <label class="col-2 mb-0 pl-0" for="inline-form-custom-select-pref">
                            {{ $t('Operator.Type') }}
                        </label>
                        <b-form-select id="inline-form-custom-select-pref" class="col-10"
                            :options="!resource_id
                                        ?   [{ text: $t('Operator.Period'), value: 'period' },
                                            { text: 'Status', value: 'status' },
                                            { text: $t('Operator.Workcenters'), value: 'workcenters' },
                                            { text: $t('Operator.Resources'), value: 'resources' }]
                                        :   [{ text: $t('Operator.Period'), value: 'period' },
                                            { text: 'Status', value: 'status' }]
                            "
                            :value="'period'"
                            @change="toggleFilter($event)"
                        >
                        </b-form-select>
                    </b-form>
                </div>
            </b-col>

            <b-col
                class="items-list-context mt-2 mt-sm-0"
                cols="12" sm="8" md="8" lg="8" xl="8"
                v-if="filterStatus"
            >
                <span class="modal-add-text">{{ $t('Operator.Select') }}</span>

                <div
                    class="status-list"
                    :class="{ 'active': status.selected }"
                    v-for="(status, index) in sequencingStatus"
                    :key="`status-${index}`"
                >
                    <span>{{ status.name }}</span>
                    <b-form-checkbox
                        v-model="status.selected"
                        v-on:change="managerSelectedFilters(status.type, status)"
                    />
                </div>
            </b-col>

            <b-col
                class="items-list-context mt-2 mt-sm-0"
                cols="12" sm="8" md="8" lg="8" xl="8"
                v-if="filterPeriod"
            >
                <span class="modal-add-text">{{ $t('Operator.Select') }}</span>
                <div v-for="period in periods" :key="`period-${period.name}`">
                    <div
                        class="status-list cursor-point-click"
                        :class="{ 'active': period.value == selectedPeriodsFilters,
                            'person-period': period.name == 'Custom'
                        }"
                        @click="managerSelectedFilters('add-period', period.value)"
                        v-if="period.name != 'Custom'"
                    >
                        <span>{{ period.name === 'AllPeriod'
                                ? $t('Operator.AllPeriod')
                                : period.name === '1Day'
                                ? ($t('Operator.Horizon') + ' ' + $t('Operator.1Day'))
                                : period.name === '5Days'
                                ? ($t('Operator.Horizon') + ' ' + $t('Operator.5Days'))
                                : period.name === '15Days'
                                ? ($t('Operator.Horizon') + ' ' + $t('Operator.15Days'))
                                : period.name === 'Custom'
                                ? $t('Operator.Custom'):'' }}</span>
                        <input
                            class="input-radio-period-filter-orders"
                            type="radio"
                            v-model="selectedPeriodsFilters"
                            :value="period.value"
                        />
                    </div>

                    <div
                        class="status-list cursor-point-click"
                        @click="managerSelectedFilters('add-period', period.value)"
                        :class="{ 'active': period.value == selectedPeriodsFilters,
                                 'person-period': period.name == 'Custom'
                        }"
                        v-else
                    >
                        <span>{{ $t('Operator.Custom') }}</span>
                        <input
                            class="input-radio-period-filter-orders"
                            type="radio"
                            v-model="selectedPeriodsFilters"
                            :value="period.value"
                        />
                        <div class="date-picker-modal-filter-orders">
                            <FormPicker
                                class="dt-picker-component"
                                type="data"
                                mode="range"
                                v-model="filterDate"
                                :minDate="''"
                                :maxDate="''"
                                :defaultDate="[]"
                                :position="'above'"
                                @input="
                                    (value) =>
                                        updateDate(
                                            value
                                        )
                                "
                            />
                        </div>
                    </div>
                </div>



            </b-col>

            <b-col
                class="items-list-context mt-2 mt-sm-0"
                cols="12" sm="8" md="8" lg="8" xl="8"
                v-if="filterWorkcenter"
            >
                <span class="modal-add-text">{{ $t('Operator.Select') }}</span>

                <b-form-input
                    :placeholder="$t('Operator.SearchWorkcenters')"
                    type="text"
                    class="d-inline-block mt-1"
                    @input="filterByInput('workcenters', $event)"
                />

                <b-row class="mt-1 mb-1">
                    <b-col cols="12">
                        <b-button
                            class="btn-initial-letter"
                            :class="{ 'selected': obj.selected }"
                            @click="filterByLetter('workcenters', obj)"
                            v-for="obj in initialWorkCenters"
                            :key="`initial-workcenter-${obj}`"
                        >
                            {{ obj.char }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-col class="col-scrollable">

                    <div
                        class="status-list"
                        :class="{ 'active': workcenter.selected }"
                        v-for="(workcenter, index) in workcentersFiltered"
                        :key="`workcenter-${index}`"
                    >
                        <span>{{ workcenter.name }}</span>
                        <b-form-checkbox
                            v-model="workcenter.selected"
                            v-on:change="managerSelectedFilters(workcenter.type, workcenter)"
                        />
                    </div>

                    <div class="not-found" v-if="workcentersFiltered.length == 0">
                        <span>
                            <SearchIcon />
                            <h3>{{ $t('Operator.NoResultsFound') }}</h3>
                        </span>
                    </div>

                </b-col>
            </b-col>

            <b-col
                class="items-list-context mt-2 mt-sm-0"
                cols="12" sm="8" md="8" lg="8" xl="8"
                v-if="filterResource"
            >
                <span class="modal-add-text">{{ $t('Operator.Select') }}</span>

                <b-form-input
                    :placeholder="$t('Operator.SearchResources')"
                    type="text" class="d-inline-block mt-1"
                    @input="filterByInput('resources', $event)"
                />

                <b-row class="mt-1 mb-1">
                    <b-col cols="12">
                        <b-button
                            v-for="obj in initialResources"
                            :key="`initial-resource-${obj}`"
                            class="btn-initial-letter"
                            :class="{ 'selected': obj.selected }"
                            @click="filterByLetter('resources', obj)"
                        >
                            {{ obj.char }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-col class="col-scrollable">
                    <div
                        class="status-list"
                        :class="{ 'active': resource.selected }"
                        v-for="(resource, index) in resourcesFiltered"
                        :key="`resource-${index}`"
                    >
                        <span>{{ resource.name }}</span>
                        <b-form-checkbox
                            v-model="resource.selected"
                            v-on:change="managerSelectedFilters(resource.type, resource)"
                        />
                    </div>

                    <div class="not-found" v-if="resourcesFiltered.length == 0">
                        <span>
                            <SearchIcon />
                            <h3>{{ $t('Operator.NoResultsFound') }}</h3>
                        </span>
                    </div>
                </b-col>
            </b-col>

            <b-col cols="12" class="col-actions">
                <b-button class="btn-template"
                    @click="cleanFilter"
                    :disabled="!(selectedStatusFilters.length > 0
                                || selectedWorkcentersFilters.length > 0
                                || selectedResourcesFilters.length  > 0
                                || selectedPeriodsFilters != '')"
                >
                    {{ $t('FilterDays.Clean') }}
                </b-button>

                <b-button class="btn-template block"
                    @click="applyFilter"
                    :disabled="!flagButtonApply"
                >
                    {{ $t('FilterDays.Submit') }}
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { BCol, BRow, BButton, BFormInput, BModal, BFormCheckbox, BFormRadio, BForm, BFormSelect } from 'bootstrap-vue'
import AddIcon from '@/assets/images/icons/icon-add.svg'
import RemoveIcon from '@/assets/images/icons/remove.svg'
import StatusIcon from '@/assets/images/icons/icon-status.svg'
import Period from '@/assets/images/icons/period.svg'
import WorkcenterIcon from '@/assets/images/icons/workcenter-icon.svg'
import ResourcesIcon from '@/assets/images/icons/resources-icon.svg'
import FormPicker from '@/@core/components/form-picker/FormPicker.vue'
import VueSlickCarousel from 'slick-vuejs'
import 'slick-vuejs/dist/slick-vuejs.css'
import 'slick-vuejs/dist/slick-vuejs-theme.css'
import moment from "moment";

export default {
    components: {
        BCol,
        BRow,
        BButton,
        BFormInput,
        AddIcon,
        BModal,
        RemoveIcon,
        StatusIcon,
        Period,
        BFormCheckbox,
        BFormRadio,
        BForm,
        BFormSelect,
        WorkcenterIcon,
        ResourcesIcon,
        FormPicker,
        VueSlickCarousel
    },
    props: ["filtersList", "calcFilters", "resource_id"],
    created() {
        this.getContext();
    },
    mounted() {

        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if(modalId == 'modal-add-filter-orders'){
                this.filterStatus = false;
                this.filterWorkcenter = false;
                this.filterPeriod = true;
                this.filterResource = false;

                this.selectedStatusFilters = this.filtersList.status ? [...this.filtersList.status] : [];
                this.selectedWorkcentersFilters = this.filtersList.workcenter ? [...this.filtersList.workcenter] : [];
                this.selectedPeriodsFilters =  this.filtersList.period != '' ? this.filtersList.period : '';
                this.selectedResourcesFilters = this.filtersList.resource ? [...this.filtersList.resource] : [];
                this.startPeriod = this.filtersList.startPeriod != '' ? this.filtersList.startPeriod : '';
                this.endPeriod = this.filtersList.endPeriod != '' ? this.filtersList.endPeriod : '';

                let statusName = this.selectedStatusFilters.map(item => item.name)
                let workcentersName = this.selectedWorkcentersFilters.map(item => item.name)
                let resourcesName = this.selectedResourcesFilters.map(item => item.name)

                this.sequencingStatus.forEach((item, i) => {
                    if(statusName.indexOf(item.name) != -1){
                        this.sequencingStatus[i].selected = !false;
                    }
                })

                this.workcenters.forEach((item, i) => {
                    if(workcentersName.indexOf(item.name) != -1){
                        this.workcenters[i].selected = !false;
                    }
                })

                this.resources.forEach((item, i) => {
                    if(resourcesName.indexOf(item.name) != -1){
                        this.resources[i].selected = !false;
                    }
                })

                this.workcentersFiltered = this.workcenters.map(item => item);
                this.resourcesFiltered = this.resources.map(item => item);

            }
        })
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if(modalId == 'modal-add-filter-orders'){
                this.flagButtonApply = false;

                this.sequencingStatus = this.sequencingStatus.map(item => {
                    return {...item, selected: false}
                })
                this.workcenters = this.workcenters.map(item => {
                    return {...item, selected: false}
                })

                this.resources = this.resources.map(item => {
                    return {...item, selected: false}
                })

                this.selectedPeriodsFilters = '';
                this.startPeriod = '';
                this.endPeriod = '';
            }
        })
    },
    data() {
        return {
            settings:{
                arrows: false,
                infinite: false,
                variableWidth: true,
                swipeToSlide: true,
                centerMode: false,
            },
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            filterPeriod: true,
            filterStatus: false,
            filterWorkcenter: false,
            filterResource: false,
            flagButtonApply: false,
            filterDate: '',
            startPeriod: '',
            endPeriod: '',
            selectedStatusFilters: [],
            selectedPeriodsFilters: '',
            selectedWorkcentersFilters: [],
            selectedResourcesFilters: [],
            initialWorkCenters: [],
            initialResources: [],
            periods: [
                { name: 'AllPeriod', value: 'AllPeriod', type:'periods'},
                { name: '1Day', value:'1Day', type:'periods'},
                { name: '5Days', value:'5Days', type:'periods'},
                { name: '15Days' , value: '15Days', type:'periods'},
                { name: 'Custom', value:'Custom', type:'periods'}
            ],
            sequencingStatus: [],
            workcenters: [],
            resources: [],
            workcentersFiltered: [],
            resourcesFiltered: [],
            workcentersFilteredByLetter: [],
            resourcesFilteredByLetter: [],
            filtersList: [],
            reRenderCarousel: 0,
        }
    },
    methods: {
        updateDate(date){
            if(date.indexOf('to') != -1 ){
                this.startPeriod = date.substring(0, 10);
                this.endPeriod = date.substring(14, 24);
            }
        },
        formatDate(date){
            let language = localStorage.getItem('language');

            if(language == 'pt'){
                return moment(date).format("DD/MM/YYYY");
            } else if (language == 'en') {
                return moment(date).format("MM/DD/YYYY");
            }
            return '';
        },
        getContext() {
            this.$http.get(`api/${this.prefix}/operator/orders/context?site_guid=${this.currentSite.guid}`)
                .then(res => {
                    res.data.workcenters.forEach(item => {
                        this.workcenters.push({name: item, selected: false, type: 'workcenters'})
                        this.workcentersFiltered.push({name: item, selected: false, type: 'workcenters'})
                    });


                    res.data.resources.forEach(item => {
                        this.resources.push({name: item, selected: false, type: 'resources'})
                        this.resourcesFiltered.push({name: item, selected: false, type: 'resources'})
                    });

                    res.data.status.forEach(item => this.sequencingStatus.push({name: item, selected: false, type: 'status'}))

                    this.workcenters.forEach(item => {
                        let letter = item.name.charAt(0);
                        let obj = { char: letter, selected: false };

                        if (!this.initialWorkCenters.find(x => x.char == letter))
                            this.initialWorkCenters.push(obj);
                    });

                    this.resources.forEach(item => {
                        let letter = item.name.charAt(0);
                        let obj = { char: letter, selected: false };

                        if (!this.initialResources.find(x => x.char == letter))
                            this.initialResources.push(obj);
                    });

                    this.initialWorkCenters = this.initialWorkCenters.sort((a, b) => {
                        if (a.char > b.char) {
                            return 1;
                        }
                        if (a.char < b.char) {
                            return -1;
                        }
                        return 0;
                    });

                    this.initialResources = this.initialResources.sort((a, b) => {
                        if (a.char > b.char) {
                            return 1;
                        }
                        if (a.char < b.char) {
                            return -1;
                        }
                        return 0;
                    });
                })

        },
        cleanFilter() {
            this.selectedStatusFilters = [];
            this.selectedWorkcentersFilters = [];
            this.selectedResourcesFilters = [];
            this.selectedPeriodsFilters = '';
            this.filterStatus = true;
            this.filterWorkcenter = false;
            this.filterPeriod = false;
            this.filterResource = false;

            this.initialWorkCenters.forEach(item => {
                item.selected = false;
            });

            this.initialResources.forEach(item => {
                item.selected = false;
            });

            this.workcenters.forEach(item => {
                item.selected = false;
            });

            this.sequencingStatus.forEach(item => {
                item.selected = false;
            });

            this.resources.forEach(item => {
                item.selected = false;
            });

            this.filtersList.workcenter = [];
            this.filtersList.status = [];
            this.filtersList.resource = [];
            this.filtersList.startPeriod = '';
            this.filtersList.endPeriod = '';
            this.filtersList.period = '';
            this.filtersList.filterDays = '';
            this.filtersList.allPeriod = '';
            this.$emit('update:filtersList', this.filtersList);
            this.calcFilters(true);
            this.$emit('filterOrdersByTags');
            this.$bvModal.hide('modal-add-filter-orders');
        },
        applyFilter() {
            this.filtersList.workcenter = [...this.selectedWorkcentersFilters];
            this.filtersList.status = [...this.selectedStatusFilters];
            this.filtersList.startPeriod = this.startPeriod;
            this.filtersList.endPeriod = this.endPeriod;
            this.filtersList.period = this.selectedPeriodsFilters;
            this.filtersList.resource = [...this.selectedResourcesFilters];
            this.filtersList.filterDays = this.selectedPeriodsFilters == '1Day'? 1 : this.selectedPeriodsFilters == '5Days' ? 5
                : this.selectedPeriodsFilters == '15Days' ? 15 : '';
            this.filtersList.allPeriod = this.selectedPeriodsFilters == 'AllPeriod' ? 1 : 0
            this.$emit('update:filtersList', this.filtersList);
            this.$emit('filterOrdersByTags');
            this.calcFilters();
            this.$bvModal.hide('modal-add-filter-orders');
        },
        filterByInput(type, input){
            if(type == 'workcenters'){
                let letterSelected = this.initialWorkCenters.find(item => item.selected == true) != undefined ? true : false;

                if (letterSelected) {
                    this.workcentersFiltered = this.workcentersFilteredByLetter.filter(item => item.name.toLowerCase().indexOf(input.toLowerCase()) != -1);
                } else {
                    let workcentersFiltered = this.workcenters.filter(item => item.name.toLowerCase().indexOf(input) != -1);
                    this.workcentersFiltered = workcentersFiltered;
                }
            } else if (type == 'resources') {
                let letterSelected = this.initialResources.find(item => item.selected == true) != undefined ? true : false;

                if (letterSelected) {
                    this.resourcesFiltered = this.resourcesFilteredByLetter.filter(item => item.name.toLowerCase().indexOf(input.toLowerCase()) != -1);
                } else {
                    let resourcesFiltered = this.resources.filter(item => item.name.toLowerCase().indexOf(input.toLowerCase()) != -1);
                    this.resourcesFiltered = resourcesFiltered;
                }
            }
        },
        filterByLetter(type, letter) {
            if(type == 'workcenters') {
                let chars = this.initialWorkCenters.map(item => item.char);
                let indexInitial = chars.indexOf(letter.char);

                this.initialWorkCenters = this.initialWorkCenters.map(item => {
                    return {...item, selected: false }
                });

                this.initialWorkCenters[indexInitial] = {...letter, selected: !letter.selected };

                let workcenters = this.workcenters.map(item => item);

                if(!letter.selected){
                    this.workcentersFilteredLetter = workcenters.filter(item => item.name[0] == letter.char)
                    this.workcentersFiltered = workcenters.filter(item => item.name[0] == letter.char)

                } else {
                    this.workcentersFilteredByLetter = [];
                    this.workcentersFiltered = workcenters
                }

            } else if(type == 'resources') {
                let chars = this.initialResources.map(item => item.char);
                let indexInitial = chars.indexOf(letter.char);

                this.initialResources = this.initialResources.map(item => {
                    return {...item, selected: false }
                });

                this.initialResources[indexInitial] = {...letter, selected: !letter.selected };

                let resources = this.resources.map(item => item);

                if(!letter.selected){
                    this.resourcesFilteredByLetter = resources.filter(item => item.name[0] == letter.char)
                    this.resourcesFiltered = resources.filter(item => item.name[0] == letter.char)

                } else {
                    this.resourcesFilteredByLetter = [];
                    this.resourcesFiltered = resources;
                }
            }
        },
        managerSelectedFilters(type, filter) {
            this.$emit("limpaListagemFiltros");

            if(type == 'remove-period') {
                this.selectedPeriodsFilters = '';
                this.filterDate = '';
            } else if(type == 'add-period') {
                this.startPeriod = ''
                this.endPeriod = ''
                this.selectedPeriodsFilters = filter;
            }  else if(type == 'status') {
                let selectedStatusName = this.selectedStatusFilters.map(item => item.name)
                let checkedStatusName = this.sequencingStatus.map(item => item.name);
                let indexSelectedStatusName = selectedStatusName.indexOf(filter.name);
                let indexCheckedStatusName = checkedStatusName.indexOf(filter.name);

                if (indexSelectedStatusName > -1 && this.selectedStatusFilters.length > 0) {
                    this.selectedStatusFilters.splice(indexSelectedStatusName, 1);
                    this.sequencingStatus[indexCheckedStatusName].selected = false;

                } else {
                    this.selectedStatusFilters.push(filter);
                }

            } else if(type == 'workcenters') {
                let selectedWorkcentersName = this.selectedWorkcentersFilters.map(item => item.name)
                let checkedWorkcentersName = this.workcenters.map(item => item.name);
                let indexSelectedWorkcentersName = selectedWorkcentersName.indexOf(filter.name);
                let indexCheckedWorkcentersName = checkedWorkcentersName.indexOf(filter.name);

                if (indexSelectedWorkcentersName > -1 && this.selectedWorkcentersFilters.length > 0) {
                    this.selectedWorkcentersFilters.splice(indexSelectedWorkcentersName, 1);
                    this.workcenters[indexCheckedWorkcentersName].selected = false;
                } else {
                    this.selectedWorkcentersFilters.push(filter);
                }

            } else if(type == 'resources') {
                let selectedResourcesName = this.selectedResourcesFilters.map(item => item.name)
                let checkedResourcesName = this.resources.map(item => item.name);
                let indexSelectedResourcesName = selectedResourcesName.indexOf(filter.name);
                let indexCheckedResourcesName = checkedResourcesName.indexOf(filter.name);

                if (indexSelectedResourcesName > -1 && this.selectedResourcesFilters.length > 0) {
                    this.selectedResourcesFilters.splice(indexSelectedResourcesName, 1);
                    this.resources[indexCheckedResourcesName].selected = false;
                } else {
                    this.selectedResourcesFilters.push(filter);

                }
            }

            this.flagButtonApply = true;
            this.reRenderCarousel++;
        },
        toggleFilter(filterName) {
            if(filterName == 'period'){
                this.filterPeriod = true;
                this.filterStatus = false;
                this.filterWorkcenter = false;
                this.filterResource = false;

            } else if (filterName == 'status') {
                this.filterPeriod = false;
                this.filterStatus = true;
                this.filterWorkcenter = false;
                this.filterResource = false;

            } else if (filterName == 'workcenters') {
                this.filterPeriod = false;
                this.filterStatus = false;
                this.filterWorkcenter = true;
                this.filterResource = false;

            } else if (filterName == 'resources') {
                this.filterPeriod = false;
                this.filterStatus = false;
                this.filterWorkcenter = false;
                this.filterResource = true;
            }

            this.reRenderCarousel++;
        },
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

// caso altere, busque todas as classes no projeto com este nome e deixe idênticas
.flatpickr-calendar{
    .flatpickr-day {
        &.startRange, &.endRange{
            background-color: #974900 !important;
            border-color: #974900 !important;
            color: #ffff !important;
        }
        &.inRange{
            box-shadow: -5px 0 0 #ECE0DB, 5px 0 0 #ECE0DB;
            background-color: #ECE0DB !important;
            border-color: #ECE0DB !important;
            color: #6e6b7b !important;
            &:hover{
            box-shadow: -5px 0 0 #ECE0DB, 5px 0 0 #ECE0DB;
            }
        }
        &.today{
            border-color: #974900;
        }
    }
}

@media(max-width: 480px) {
    #modal-add-filter-orders {
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-height: 100vh;

        .items-list-context {
            height: 100vh;
            overflow: scroll;
        }

        .div-type-filter-mobile {
            height: 8vh;
            margin-bottom: 0px !important;
        }

        .modal-content {
            height: 100vh;
            width: 100vw;
        }
        .modal-body {
            overflow: hidden !important;
        }
        .modal-dialog {
            margin: 0 !important
        }

        .btn-template {
            height: 38px !important;
            border: none !important
        }

        .col-actions {
            background: #fff;
            position: fixed;
            height: 10vh;
            bottom: 0px;
            left: 0;
            display: flex;
            align-items: center;
        }

        .content-modal-filter-orders {
            position: relative;
        }

        .modal-header {
            height: 8vh;
            background: #fff !important;
            .close {
                left: 0 !important;
                transform: none !important;
                background-color: #f8f8f8 !important;
                top: 0 !important;
                box-shadow: none !important;
                padding: 0.8rem 1.4rem !important
            }
            .close.close-mobile {
                background-color: #fff !important;
            }
        }
    }
}

#modal-add-filter-orders {

    .input-radio-period-filter-orders{
        height: 18px;
        width: 18px;
        margin-right: 0.5rem;

        accent-color: #974900;
    }
    .cursor-point-click {
        cursor: pointer;
    }

    .date-picker-modal-filter-orders {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        fieldset {
            height: 100%;
            div {
                height: 100%;

                input {
                    height: 100%;
                }
            }
        }

        input {
            color: transparent;
            border: none;
            cursor: pointer;
            &:focus {
                box-shadow: none;
            }
        }
        .form-group {
            margin-bottom: 0 !important;
        }
        svg {
            display: none;
        }

        .dt-picker-component {
            height: 100%;
        }
    }

    .person-period {
        position: relative !important;
    }
    .selected-filter {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 8px 6px 12px;
        gap: 10px;
        height: 32px;
        background: #FFDBC4 !important;
        border-radius: 8px;
        line-height: 20px;

        font-weight: 600;
        font-size: 12px;
        color: #974900 !important;
        border: none !important;

        &:hover {
            box-shadow: none !important;
        }

        .cursor-pointer{

            margin-left: 12px;
        }
    }

    .slick-slide {
        gap: 15px;
        padding: 0 5px;
        margin-bottom: 1.5rem;

        &::-webkit-scrollbar {
            width: 0 !important;
        }
    }

    .modal-footer {
        display: none;
    }

    .modal-content {
        .modal-header {
            padding: 20px 35px;

            .modal-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #4C4541;
            }

            .close {
                transform: none !important;
                transition: none !important;
                top: -12px;
                left: 15px;
            }
        }

        .add-row {
            svg {
                position: absolute;
            }

            .btn {
                background: #974900 !important;
                border-radius: 5px;
                border: none;
                width: 10px;
                display: flex;
                justify-content: center;
                height: 36px;
            }
        }

        .remove-row {
            svg {
                position: absolute;
            }

            .btn {
                background: #D32F2F !important;
                border-radius: 5px;
                border: none;
                width: 10px;
                display: flex;
                justify-content: center;
                height: 36px;
            }
        }

        .save-button {
            .btn {
                background-color: #974900 !important;
                border-radius: 5px;
                border: none;
                font-size: 17px;
                width: 100px;
                height: 45px;
            }
        }

        .modal-add-text {
            font-weight: 600;
            font-size: 14px;
            color: #998F8A;
        }

        .selected-filter {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 8px 6px 12px;
            gap: 10px;
            height: 32px;
            background: #FFDBC4 !important;
            border-radius: 8px;
            line-height: 20px;

            font-weight: 600;
            font-size: 12px;
            color: #974900 !important;
            border: none !important;

            &:hover {
                box-shadow: none !important;
            }
        }

        .col-selected-items {
            display: flex;
            flex-direction: row;
            overflow: scroll;
            flex-wrap: nowrap;
            gap: 15px;
            margin-bottom: 1.5rem;

            &::-webkit-scrollbar {
                width: 0 !important;
            }
        }

        .filter-type {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            background: #FFEDE2;
            box-shadow: 0px 3px 6px rgba(72, 32, 3, 0.4);
            border-radius: 6px;

            &.active {
                background: #974900;

                svg {
                    filter: brightness(2.5);
                }

            }
        }

        .div-type {
            margin-top: 1rem;
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 600;
            font-size: 14px;
            color: #974900;
            cursor: pointer;
        }

        .status-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FCFCFC;
            border: 1px solid #998F8A;
            border-radius: 6px;
            height: 52px;
            margin-top: 0.8rem;
            padding: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4C4541;

            &.active {
                background: #FFEDE2;
                color: #974900;
                border-color: #974900;
            }
        }

        .custom-control-input:checked~.custom-control-label::before {
            background-color: #974900 !important;
            border-color: #974900 !important;
        }

        .btn-initial-letter {
            border: none !important;
            background: #FFF !important;
            color: #974900 !important;
            margin-right: 10px;
            width: 37px;
            height: 28px;
            padding: 0px 0px !important;
            font-weight: 600;

            &:hover {
                box-shadow: none !important;
                background: #FFEDE2 !important;
            }

            &.selected {
                background-color: #974900 !important;
                color: #FFF !important;
            }
        }

        .col-scrollable {
            overflow: auto;
            height: 200px;
            padding-left: 0px;

            &::-webkit-scrollbar {
                border-radius: 10px;
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: #CFC4BE;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }
        }

        .col-actions {
            margin-top: 3rem;
            display: flex;
            justify-content: end;
            gap: 15px;
        }

        .btn-template {
            height: 38px;
            border: 1px solid #974900 !important;
            color: #974900 !important;
            background: #FFF !important;
            font-weight: 600;
            font-size: 14px;

            &:disabled{
                &:hover{
                    background: transparent !important;
                }
                &.block:hover{
                    background: #974900 !important
                }
            }
            &:hover {
                box-shadow: none !important;
                background: #FFEDE2 !important;
            }

            &.block {
                background: #974900 !important;
                color: #FFF !important;
            }
        }

        .div-type-filter-mobile {
            display: none;
        }
    }
}

</style>
