<template>
  <div id="container-operator-mode">
    <div class="container-fluid">

      <b-row>
        <span class="title-operator">{{ $t('Operator Mode') }}</span>
      </b-row>

      <b-row class="row-buttons-pages">
        <b-col md="12" class="col-btn-pages">
          <div :class="['button-page', flagMaquina && 'active']" @click="habilitaInfos('maquina')">
            <span class="title-button">{{ $t('Operator.Sequencing') }}</span>
            <span class="sub-title-button">{{ $t('Operator.ResourcesDescription') }}</span>
          </div>
          <div :class="['button-page', flagOrdem && 'active']" @click="habilitaInfos('ordens')">
            <span class="title-button">{{ $t('Operator.Orders') }}</span>
            <span class="sub-title-button">{{ $t('Operator.OrdersProgressDescription') }}</span>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="p-0 mt-2">
          <b-card v-if="flagMaquina" class="text-center">
            <resource @atualizaShowOverlay="(value) => atualizaShowOverlay(value)" />
          </b-card>

          <b-card v-if="flagOrdem" class="text-center">
            <orders @atualizaShowOverlay="(value) => atualizaShowOverlay(value)" />
          </b-card>
        </b-col>
      </b-row>

      <button @click="topPage" class="btn-scroll" v-if="btnScrollVisible">
        <top-icon></top-icon>
      </button>
    </div>
  </div>

</template>

<script>
import { BCard, BCol, BRow, BButton } from 'bootstrap-vue'
import Resource from '@/views/pages/operator/components/Resources.vue'
import Orders from '@/views/pages/operator/components/Orders.vue'
import Ripple from 'vue-ripple-directive'
import TopIcon from '@/assets/images/icons/top.svg'
import { mapMutations } from "vuex";

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    Resource,
    Orders,
    TopIcon,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
      flagMaquina: true,
      flagOrdem: false,
      btnScrollVisible: true
    }
  },
  created() {
    // window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    // window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    ...mapMutations('app', [
      'UPDATE_FLAG_SHOW_OVERLAY'
    ]),
    atualizaShowOverlay(value){
      this.UPDATE_FLAG_SHOW_OVERLAY(value);
    },
    habilitaInfos(tipo) {
      this.flagMaquina = false;
      this.flagOrdem = false;

      if (tipo == "maquina") {
        this.flagMaquina = true;
      } else if (tipo == "ordens") {
        this.flagOrdem = true;
      }
    },
    topPage() {
      window.scroll(0, 0);
    }
  }
}
</script>

<style lang="scss">

.title-operator {
  font-weight: 600;
  font-size: 36px;
  line-height: 50px;
  color: #4C4541;
  padding-bottom: 1rem;
}

.row-buttons-pages {
  width: 100%;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none !important;
  }

  .col-btn-pages {
    min-width: 715px;
    display: flex;
    padding-bottom: 4px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;

    .button-page {
      padding: 8px;
      background-color: transparent !important;
      display: flex;
      flex-direction: column;
      border: none;
      border-radius: 6px;
      margin-right: 20px;
      width: 230px;

      span {
        color: #998F8A !important;
      }

      .title-button {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 4px;
      }

      .sub-title-button {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }

      &:hover {
        cursor: pointer;
      }

      &.active {
        background-color: #974900 !important;

        span {
          color: #ffff !important;
        }
      }
    }
  }
}

.btn-scroll {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 12px;
  border: none;
  outline: none;
  color: white;

  width: 36px;
  height: 36px;
  background: #974900;
  box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
</style>
