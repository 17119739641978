<template>
    <div>
        <modal-filter
            :filtersList.sync="filtersList"
            :calcFilters="calcFiltersSize"
            @filterOrdersByTags="filterOrdersByTags"
            :resource_id="resource_id"
            @limpaListagemFiltros ="limpaListagemFiltros"
        />

        <b-row v-if="resource">
            <b-col cols="12"
                class="d-flex flex-column flex-md-row flex-lg-row flex-xl-row justify-content-between mb-3">
                <span
                    class="detail-title order-2 order-md-1 order-lg-1 order-xl-1 align-self-start mt-1 mt-md-0 mt-lg-0 mt-xl-0">{{
                            resource.name
                    }}</span>
                <div class="btn-back order-1 order-md-2 order-lg-2 order-xl-2 align-self-start" @click="backResources">
                    <back-icon />
                    {{ $t('Operator.Previous') }}
                </div>
            </b-col>
        </b-row>
        <b-row v-if="showMessage">
            <b-col cols="12" @click="focusInputBarcode">
                <b-alert show class="d-none d-lg-block alert-orders">
                    {{ $t('Operator.Press') }}
                    <span class="esc"> Esc </span>
                    {{ $t('Operator.MessageOrdersDesktop') }}
                </b-alert>
                <b-alert show class="d-lg-none alert-orders">
                    {{ $t('Operator.MessageOrdersMobTab') }}
                </b-alert>
            </b-col>
        </b-row>

        <b-row :class="filtersSize == 0 ? 'mb-2' : ''">
            <b-col cols="12" lg="9" xl="10">
                <b-form-group>
                    <b-form-input v-model="searchTerm" :placeholder="$t('Operator.SearchOrders')" type="text" id="order-filter-input"
                        class="d-inline-block" @keyup="filterOrders($event)" @focus="ActiveFilterInputFocus = true"
                        @blur="ActiveFilterInputFocus = false" tabindex="-1"/>
                </b-form-group>
            </b-col>

            <b-col cols="12" lg="3" xl="2">
                <b-button class="d-none d-sm-block btn-add-filter" v-b-modal.modal-add-filter-orders>
                    <add-icon class="icon-filter-orders" />
                    {{ $t('Operator.AddFilter') }}
                </b-button>
                <b-button :class="`${filtersSize > 0 ? 'filter-with-size' : 'btn-add-filter'} d-sm-none `" v-b-modal.modal-add-filter-orders>
                    <IconFilter class="icon-filter-orders"/>
                        {{$t('Operator.Filter')}}
                    <div class="circle-filters-size" v-if="filtersSize > 0">
                        <span>{{ filtersSize }}</span>
                    </div>
                </b-button>
            </b-col>
        </b-row>

        <b-col class="col-12 orders-filtro" v-if="filtersList.workcenter.length > 0 || filtersList.status.length > 0 || filtersList.period != '' || filtersList.resource.length > 0">
            <button type="button" @click="clearFilters" class="limpar-filtros-btn d-none d-sm-block">{{ $t('Operator.CleanFilter') }}</button>
                <VueSlickCarousel
                    v-bind="settings"
                    :key="`carousel-filters-orders-${reRenderCarousel}`"
                    class="filtros-area"
                >
                    <div class="filtro-item" v-if=" filtersList.period == 'Custom'" :key="'period-filter-item-' + filtersList.period">
                        {{
                            `${chipDateFormat(filtersList.startPeriod)} ${$t('Operator.Until')} ${chipDateFormat(filtersList.endPeriod)}`
                        }}
                        <imgDeleteIcon @click="deleteFilteredItem('period', '')" />
                    </div>
                    <div class="filtro-item" v-if=" filtersList.period != 'AllPeriod' && filtersList.period != 'Custom' && filtersList.period != ''" :key="'period-filter-item-' + filtersList.period">
                        {{
                            filtersList.period === '1Day'
                            ?($t('Operator.Horizon') + $t('Operator.1Day'))
                            : filtersList.period === '5Days'
                            ? ($t('Operator.Horizon') + ' ' + $t('Operator.5Days'))
                            : filtersList.period === '15Days'
                            ? ($t('Operator.Horizon') + ' ' + $t('Operator.15Days')): ''
                        }}
                        <imgDeleteIcon @click="deleteFilteredItem('period', '')" />
                    </div>
                    <div class="filtro-item" v-if="filtersList.period == 'AllPeriod'" :key="'period-filter-item-' + filtersList.period">
                        {{
                            $t('Operator.AllPeriod')
                        }}
                        <imgDeleteIcon @click="deleteFilteredItem('period', '')" />
                    </div>
                    <div class="filtro-item" v-for="status in filtersList.status" :key="'status-filter-item-' + status.name">
                        {{ status.name }}<imgDeleteIcon @click="deleteFilteredItem('status', status.name)" />
                    </div>
                    <div class="filtro-item" v-for="workcenter in filtersList.workcenter" :key="'workcenter-filter-item' + workcenter.name">
                        {{ workcenter.name }}<imgDeleteIcon @click="deleteFilteredItem('workcenter', workcenter.name)" />
                    </div>
                    <div class="filtro-item" v-for="resource in filtersList.resource" :key="'resource-filter-item-' + resource.name">
                        {{ resource.name }}<imgDeleteIcon @click="deleteFilteredItem('resource', resource.name)" />
                    </div>
                </VueSlickCarousel>
        </b-col>

        <b-row class="ml-0 mr-0">
            <div class="div-checkbox pr-2">
                <span class="resource-title">{{ $t('Orders') }}</span>
            </div>

            <b-col cols="12" md="8" lg="9" xl="9"
                class="div-checkbox p-0 order-2 order-md-1 order-lg-1 order-xl-1 flex-wrap mt-1 mt-md-0 mt-lg-0 mt-xl-0">
                <b-form-checkbox switch inline v-model="late" @change="filterOrders">
                    <span class="text-checkbox"> {{ $t('Operator.Late') }}</span>
                </b-form-checkbox>

                <b-form-checkbox switch inline v-model="surplus" @change="filterOrders">
                    <span class="text-checkbox">{{ $t('Operator.Surplus') }}</span>
                </b-form-checkbox>

                <b-form-checkbox switch inline v-model="multiSelect" @change="verificaFormatacao">
                    <span class="text-checkbox">{{ $t('Operator.MultiSelection') }}</span>
                </b-form-checkbox>
                <b-form-input id="input-barcode" v-model="barcode" ref="barcode" class="barcode" @blur="showMessageInterval()"
                    @focus="(showMessage = false)" :key="`input-barcode-${controlRenderInputBarCode}`" @keydown="watchInputBarCode($event)" tabindex="-2"
                />
            </b-col>

            <b-col cols="6" md="2" lg="2" xl="2"
                class="ml-auto p-0 text-right order-1 order-md-2 order-lg-2 order-xl-2">
                <span class="total-results">
                    {{ ordersFiltered.length }}
                    <span v-if="ordersFiltered.length === 1">{{ $t('Operator.Result') }}</span>
                    <span v-else>{{ $t('Operator.Results') }}</span>
                </span>
            </b-col>
        </b-row>

        <div class="not-found" v-if="ordersFiltered.length == 0">
            <span>
                <SearchIcon />
                <h3>{{ $t('Operator.NoResultsFound') }}</h3>
            </span>
        </div>
        <b-row class="row-cards">
            <b-col cols="12" lg="6" xl="6" v-for="(order, index) in ordersFiltered" :key="`${order.id}-${index}`"
                class="col-cards" @click="!order.disabled ? createSelectedsList(order) : ''" :style="`opacity: ${!order.disabled ? '1' : '0.6'};`">
                <b-button class="card-orders" :to="!multiSelect ? {name: 'operator-production-progress', params: { prefix: prefix, order_id: order.preactor_order_id, resource_id: order.resource_id, return_orders: 'true', multiple: 'false' } } : ''">

                    <div class="col-item-card" :style="`border:1px solid ${order.color}; `">
                        <div class="card-orders-header" :style="getStyle('header', order)">
                            <b-form-checkbox v-if="multiSelect" :checked="order.selected" disabled/>
                            <div class="d-flex flex-column text-left">
                                <span class="order-name">{{ order.order_no }}</span>
                                <span class="resource-name-sub-title">{{ resource ? resource.name : order.resource
                                }}</span>
                            </div>
                            <b-badge variant="light" class="badge-status ml-auto" :style="`color: ${order.color};`">
                                {{ order.status }}
                            </b-badge>
                        </div>

                        <div class="card-orders-body" >
                            <div class="card-order-status" v-if="Number(order.lated)" :style="getStyle('alert', order)">
                                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        :fill="getStyle('alert-icon', order)"
                                        d="M8.25 0.666748L0.25 15.3334H16.25L8.25 0.666748ZM7.58333
                                        6.00008H8.91667V10.6667H7.58333V6.00008ZM8.25 13.5001C7.79 13.5001 7.41667 13.1267
                                        7.41667 12.6667C7.41667 12.2067 7.79 11.8334 8.25 11.8334C8.71 11.8334 9.08333 12.2067
                                        9.08333 12.6667C9.08333 13.1267 8.71 13.5001 8.25 13.5001Z"
                                    />
                                </svg>
                                <span class="d-flex align-items-center">
                                    {{ $t('Operator.Late') }}
                                </span>
                            </div>

                            <div class="mt-2">
                                <div class="d-flex justify-content-between">
                                    <span class="text-label">{{ $t('OperationProgress.RealProgress') }}</span>
                                    <span class="percent">{{ order.progress }}%</span>
                                </div>

                                <b-progress class="mb-2">
                                    <b-progress-bar :value="order.progress" :max="100" :style="getStyle('progress', order)">
                                    </b-progress-bar>
                                </b-progress>
                            </div>

                            <b-row class="div-detail-orders flex-column flex-md-row flex-lg-row flex-xl-row">
                                <b-col cols="12" md="6" lg="6" xl="6"
                                    class="detail-orders flex-row flex-md-column flex-lg-column flex-xl-column">
                                    <span class="text-label">{{ $t('Operator.OperationNumber') }}</span>
                                    <span class="percent">{{ order.op_no }}</span>
                                </b-col>

                                <b-col cols="12" md="6" lg="6" xl="6"
                                    class="detail-orders flex-row flex-md-column flex-lg-column flex-xl-column order-3 order-md-1 order-lg-1 order-xl-1 align-items-end">
                                    <span class="text-label">{{ $t('ordersList.SetupStart') }}</span>
                                    <span class="percent">{{ cardDateHourFormat(order.setup_start)  }}</span>
                                </b-col>

                                <b-col cols="12" md="6" lg="6" xl="6"
                                    class="detail-orders flex-row flex-md-column flex-lg-column flex-xl-column order-1 order-md-2 order-lg-2 order-xl-2">
                                    <span class="text-label">{{ $t('ordersList.Product') }}</span>
                                    <span class="percent">{{ order.part_no }}</span>
                                </b-col>

                                <b-col cols="12" md="6" lg="6" xl="6"
                                    class="detail-orders flex-row flex-md-column flex-lg-column flex-xl-column order-4 order-md-3 order-lg-3 order-xl-3 align-items-end">
                                    <span class="text-label">{{ $t('Operator.StartTime') }}</span>
                                    <span class="percent">{{ cardDateHourFormat(order.start_time) }}</span>
                                </b-col>

                                <b-col cols="12" md="6" lg="6" xl="6"
                                    class="detail-orders flex-row flex-md-column flex-lg-column flex-xl-column order-2 order-md-4 order-lg-4 order-xl-4">
                                    <span class="text-label">{{ $t('Operator.OperationDescription') }}</span>
                                    <span class="percent">{{ order.operation_name }}</span>
                                </b-col>

                                <b-col cols="12" md="6" lg="6" xl="6"
                                    class="detail-orders flex-row flex-md-column flex-lg-column flex-xl-column order-5 align-items-end">
                                    <span class="text-label">{{ $t('Operator.EndTime') }}</span>
                                    <span class="percent">{{ cardDateHourFormat(order.end_time) }}</span>
                                </b-col>
                            </b-row>
                        </div>
                    </div>
                </b-button>
            </b-col>
        </b-row>

        <Transition name="slide-fade">

            <b-button class="new-note d-flex align-items-center"
                v-if="selectedOrders.length > 0"
                :to="selectedOrders.length > 1
                    ?   {name: 'operator-production-progress-multiple',
                            params: {
                                prefix: prefix,
                                resource_id: selectedOrders[0].resource_id,
                                multiple: 'true',
                                preactor_orders: selectedOrders.map(item => item.preactor_order_id).join(','),
                                orders: selectedOrders
                            }
                        }
                    :   {name: 'operator-production-progress',
                            params: {
                                prefix: prefix,
                                resource_id: selectedOrders[0].resource_id,
                                return_orders: 'true',
                                multiple: 'false',
                                order_id: selectedOrders[0].preactor_order_id,
                                orders: []
                            }
                        }
                ">
                {{ $t('Operator.NewNote') }}
            </b-button>
        </Transition>
    </div>
</template>

<script>
import {
    BCard,
    BCol,
    BRow,
    BButton,
    BLink,
    BAlert,
    BCardText,
    BNavItem,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BBadge,
    BProgress,
    BProgressBar,
    BFormCheckbox,
    BModal,
    BForm,
    BFormSelect,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BackIcon from '@/assets/images/icons/back.svg'
import AddIcon from '@/assets/images/icons/icon-add.svg'
import RemoveIcon from '@/assets/images/icons/remove.svg'
import IconFilter from '@/assets/images/pages/filter-icon.svg'
import LoadingIcon from '@/assets/images/icons/loading.svg'
import SearchIcon from '@/assets/images/pages/Search.svg'
import ModalFilter from './ModalOrdersFilter.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from "moment";
import imgDeleteIcon from '@/assets/images/icons/delete-icon.svg'
import VueSlickCarousel from 'slick-vuejs'
import 'slick-vuejs/dist/slick-vuejs.css'
import 'slick-vuejs/dist/slick-vuejs-theme.css'

export default {
    components: {
        BCard,
        BCol,
        BRow,
        BButton,
        BLink,
        BAlert,
        BCardText,
        BNavItem,
        BFormGroup,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BBadge,
        BProgress,
        BackIcon,
        AddIcon,
        BFormCheckbox,
        BModal,
        RemoveIcon,
        IconFilter,
        BForm,
        BFormSelect,
        LoadingIcon,
        SearchIcon,
        BProgressBar,
        ModalFilter,
        ToastificationContent,
        VueSlickCarousel,
        imgDeleteIcon
    },
    directives: {
        Ripple,
    },
    props: {
        resource: Object
    },
    watch: {
        barcode(v){
            setTimeout(this.clearBarCode, 500);
        },
        late(v){
            if(v){
                this.surplus = !v;
            }
        },
        surplus(v){
            if(v){
                this.late = !v;
            }
        },
        multiSelect(newValue) {
            if(!newValue){
                this.orders.forEach(item => {item.selected = false; item.disabled = false;})
                this.ordersFiltered.forEach(item => {item.selected = false; item.disabled = false;})
                this.ordersFilteredByTag.forEach(item => {item.disabled = false; item.selected = false;})
                this.ordersMultiselectFiltered = [];
                this.selectedOrders = [];
                this.filterOrders();
            }
        },
        searchTerm(v) {
            if(this.getHiddenOrder)  {
                this.getHiddenOrder = false;
            }
            this.verificaFormatacao()
        },
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            settings:{
                arrows: false,
                infinite: false,
                variableWidth: true,
                swipeToSlide: true,
                centerMode: false,
            },
            orders: [],
            ordersList: [],
            ordersMultiselectFiltered:[],
            selectedOrders:[],
            statusList: [],
            resource_id: null,
            filterDaysOption: [{ label: 7, value: 7 }, { label: 45, value: 45 }, { label: 60, value: 60 }, { label: 'All', value: 3000 }],
            filterDays: { label: 7, value: 7 },
            searchTerm: '',
            ordersFiltered: [],
            ordersFilteredByTag: [],
            appliedFilter: false,
            late: false,
            surplus: false,
            multiSelect: false,
            barcode: '',
            controlRenderInputBarCode: 0,
            reRenderCarousel: 0,
            showMessage: false,
            filtersList: {
                workcenter: [],
                status: [],
                resource: [],
                startPeriod: '',
                endPeriod: '',
                period: '',
                filterDays:'',
                allPeriod:''
            },
            filtersSize: 0,
            firstLoading:true,
            ordersAntigas:[],
            ordersAntigasFiltradas:[],
            getHiddenOrder: false,
            ordersBackup: [],
            ordersFilteredBackup: [],
            unselectedOrders: [],
            backendOrdersList:[],
            ActiveFilterInputFocus: false,
            loadingOrders: false,
        }
    },
    mounted() {
        window.addEventListener('keydown', (e) => {
            if (e.key == 'Escape') {
                this.$refs.barcode.focus();
            }
        });

        this.$refs.barcode.focus();

        if (this.resource)
            this.resource_id = this.resource.preactor_resource_id;

        this.scroll();
    },
    destroyed() {
        window.removeEventListener('keydown');
    },
    methods: {
        limpaListagemFiltros(){
            this.ordersAntigas = [];
            this.ordersAntigasFiltradas = []
        },
        scroll() {
            if(this.firstLoading){
                this.getOrders('', this.searchTerm);
            }
            window.onscroll = () => {
                document.getElementById('order-filter-input').blur();
                let bottomOfWindow = Math.round(window.innerHeight + window.pageYOffset) >= document.documentElement.scrollHeight;
                if(bottomOfWindow) this.getHiddenOrder = false;
                if (bottomOfWindow && this.filtersSize > 0){
                    this.filterOrdersByTags(this.searchTerm)
                }
                else if (bottomOfWindow && !this.firstLoading && this.ordersFiltered?.length > 0 && !this.getHiddenOrder && !this.ActiveFilterInputFocus && !this.loadingOrders) {
                    this.getOrders('moreOrders', this.searchTerm);
                }
            }
        },
        verificaFormatacao(){
            this.orders.forEach((element, index) => {
                const tamanhoCard = document.getElementsByClassName("card-orders-header")[index]?.clientWidth
                const tamanhoStatus = document.getElementsByClassName("badge-status")[index]?.clientWidth
                const espacamentosCard = 70
                if(document.documentElement?.clientWidth < 480){
                    document.getElementsByClassName("badge-status")[index]?.setAttribute("style", `max-width:98px !important; overflow: hidden;
                        text-overflow: ellipsis;color:${element.color}`);

                    document.getElementsByClassName("resource-name-sub-title")[index]?.setAttribute("style", `max-width:${tamanhoCard - (96 + espacamentosCard)}px;`);
                    document.getElementsByClassName("order-name")[index]?.setAttribute("style", `max-width:${tamanhoCard - (96 + espacamentosCard)}px;`);
                }
                else{
                    if(tamanhoStatus > 230){
                        document.getElementsByClassName("badge-status")[index]?.setAttribute("style", `max-width:230px; overflow: hidden;
                        text-overflow: ellipsis;color:${element.color}`);
                        document.getElementsByClassName("resource-name-sub-title")[index]?.setAttribute("style", `max-width:${tamanhoCard - (230 + espacamentosCard)}px;`);
                        document.getElementsByClassName("order-name")[index]?.setAttribute("style", `max-width:${tamanhoCard - (tamanhoStatus + espacamentosCard)}px;`);
                    }
                    else{
                        document.getElementsByClassName("resource-name-sub-title")[index]?.setAttribute("style", `max-width:${tamanhoCard - (tamanhoStatus + espacamentosCard)}px;`);
                        document.getElementsByClassName("order-name")[index]?.setAttribute("style", `max-width:${tamanhoCard - (tamanhoStatus + espacamentosCard)}px;`);

                    }
                }

            })
        },
        filterOrdersByTags(search = ''){
            return new Promise((resolve, reject) => {
                this.$emit("atualizaShowOverlay", true);

                let resources = this.filtersList.resource
                    ? this.filtersList.resource.map(item => item.name).join(',')
                    : ''

                let workcenters = this.filtersList.workcenter
                    ? this.filtersList.workcenter.map(item => item.name).join(',')
                    : ''

                let status = this.filtersList.status
                    ? this.filtersList.status.map(item => item.name).join(',')
                    : ''

                let startTime = this.filtersList.startPeriod
                    ? moment(this.filtersList.startPeriod.split('/').reverse().join('-')).format('YYYY-MM-DD')
                    : '';

                let endTime = this.filtersList.endPeriod
                    ? moment(this.filtersList.endPeriod.split('/').reverse().join('-')).format('YYYY-MM-DD')
                    : '';

                let lastOrder = this.ordersAntigasFiltradas.length > 0 ? this.ordersAntigasFiltradas.at(-1).id : '';

                this.$http.get(`/api/${this.prefix}/operator/orders?site_guid=${this.currentSite.guid}
                                    &preactor_resource_id=${this.resource_id ? this.resource_id : ''}
                                    &status=${status}
                                    &resources=${resources}
                                    &workcenters=${workcenters}
                                    &start_date=${startTime}
                                    &end_date=${endTime}
                                    &filterDays=${this.filtersList.filterDays}
                                    &all_period=${this.filtersList.allPeriod}
                                    &order_id=${search == '' ? lastOrder : ''}
                                    &filter=${search}

                `)
                    .then(res => {
                        if(search == '') {
                            if(res.data.response != []) {

                                let ordersAPI = res.data.response.map(item => this.selectedOrders?.findIndex(order => order.id == item.id) == -1 ? {...item, selected: false, disabled: false} : {...item, selected: true, disabled: false});
                                let ordersTotais = [...this.ordersAntigasFiltradas,...ordersAPI];
                                let ordersTotalSorted = [...this.ordersAntigasFiltradas,...ordersAPI];
                                this.ordersAntigasFiltradas = [...ordersTotais];

                                ordersTotalSorted.sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                                this.orders = [...ordersTotalSorted];
                                this.ordersFiltered = [...ordersTotalSorted];
                                this.ordersFilteredByTag = [...ordersTotalSorted];

                                this.orders.forEach(item => {
                                    if(this.selectedOrders?.length > 0) {
                                        item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                        : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                    }
                                });
                                this.ordersMultiselectFiltered = [];
                            }
                        } else {
                                    this.ordersAntigasFiltradas = [...this.ordersFilteredByTag];

                                    let ordersAPI = res.data.response.map(order => {return{...order, selected: false, disabled: false}})

                                    this.ordersAntigasFiltradas = [...this.ordersAntigasFiltradas, ...ordersAPI].filter((item, index, array) => {
                                        return array.findIndex((elem) => {
                                            return elem.id === item.id
                                        }) === index;
                                    })

                                    this.ordersAntigasFiltradas.forEach(item => {
                                        if(this.selectedOrders?.length > 0) {
                                            item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                            : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                        }
                                    });

                                    this.orders = [...this.ordersAntigasFiltradas, ...ordersAPI].filter((item, index, array) => {
                                        return array.findIndex((elem) => {
                                            return elem.id === item.id
                                        }) === index;
                                    })

                                    this.orders.forEach(item => {
                                        if(this.selectedOrders?.length > 0) {
                                            item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                            : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                        }
                                    });

                                    this.ordersFiltered = [...this.ordersAntigasFiltradas, ...ordersAPI].filter((item, index, array) => {
                                        return array.findIndex((elem) => {
                                            return elem.id === item.id
                                        }) === index;
                                    })

                                    this.ordersFiltered.forEach(item => {
                                        if(this.selectedOrders?.length > 0) {
                                            item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                            : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                        }
                                    });

                                    this.ordersFilteredByTag = [...this.ordersAntigasFiltradas, ...ordersAPI].filter((item, index, array) => {
                                        return array.findIndex((elem) => {
                                            return elem.id === item.id
                                        }) === index;
                                    })

                                    this.ordersFilteredByTag.forEach(item => {
                                        if(this.selectedOrders?.length > 0) {
                                            item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                            : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                        }
                                    });

                                    if(this.ordersMultiselectFiltered?.length > 0) {
                                        this.ordersMultiselectFiltered = [...this.ordersMultiselectFiltered, ...ordersAPI].filter((item, index, array) => {
                                            return array.findIndex((elem) => {
                                                return elem.id === item.id
                                            }) === index;
                                        })

                                        this.ordersMultiselectFiltered.forEach(item => {
                                            if(this.selectedOrders?.length > 0) {
                                                item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                                : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                            }
                                        });
                                    }
                        }

                        if(this.filtersSize == 0 ){
                            this.getOrders('moreOrders', search)
                            this.ordersAntigasFiltradas = []
                            let orderSelected = this.ordersFiltered.find(item => item.selected);
                            if (orderSelected != undefined) {
                                if(orderSelected.selected && orderSelected.control_by_hour == '1') {
                                    this.ordersFiltered = this.ordersFiltered.map(item => {
                                        if(item.id != order.id){
                                            item.disabled = true;
                                        }
                                        return item;
                                    })

                                    let orders = this.ordersFiltered;
                                    orders.splice(indexOrderFiltered, 1);
                                    let modifiedOrdersSorted = orders.sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                                    this.ordersFiltered = [order, ...modifiedOrdersSorted];
                                    this.selectedOrders = [order];
                                    return;

                                } else if (orderSelected.selected && orderSelected.control_by_hour == '0'){
                                    let ordersQuantity = this.ordersFiltered.map(item => {

                                        if( item.status != orderSelected.status || item.control_by_hour == '1' ){
                                            item.disabled = true;
                                        }

                                        return item;
                                    });
                                    let selectedOrders = ordersQuantity.filter(item => item.selected == true).sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                                    let unselectedOrders = ordersQuantity.filter(item => item.selected == false).sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                                    this.ordersMultiselectFiltered = [...selectedOrders, ...unselectedOrders]
                                    this.selectedOrders = selectedOrders;
                                    this.unselectedOrders = unselectedOrders;
                                    this.ordersFiltered = [...selectedOrders, ...unselectedOrders];

                                } else if (this.ordersFiltered.filter(item => item.selected).length == 0) {
                                    this.ordersFiltered = this.orders.map(item => {
                                        item.disabled = false;

                                        return item
                                    })
                                }

                            }
                        }

                        this.filterOrders();

                        this.reRenderCarousel++;
                        this.$emit("atualizaShowOverlay", false);

                        resolve();
                    }).catch(() => {
                        this.$emit("atualizaShowOverlay", false)
                        reject();
                    })

            })
        },
        calcFiltersSize(clear = false){
            this.filtersSize = !clear
            ? this.filtersList.workcenter.length + this.filtersList.status.length
                + this.filtersList.resource.length + (this.filtersList.period != '' ? 1 : 0)
            : 0;
        },
        clearFilters() {
            this.limpaListagemFiltros()
            this.filtersList.workcenter = [];
            this.filtersList.status = [];
            this.filtersList.resource = [];
            this.filtersList.startPeriod = '';
            this.filtersList.endPeriod = '';
            this.filtersList.period = '';
            this.filtersList.filterDays = '';
            this.filtersList.allPeriod = 0;
            this.calcFiltersSize(true);
            this.filterOrdersByTags(this.searchTerm);
        },
        deleteFilteredItem(type = null, name = null) {
            this.limpaListagemFiltros()
            if(type == 'workcenter') {
                this.filtersList.workcenter.splice(
                    this.filtersList.workcenter.indexOf(
                        this.filtersList.workcenter.find((item) => item.name == name)), 1)
            } else if(type == 'status') {
                this.filtersList.status.splice(
                    this.filtersList.status.indexOf(
                        this.filtersList.status.find((item) => item.name == name)), 1)
            } else if(type == 'period') {
                this.filtersList.period = '';
                this.filtersList.startPeriod = '';
                this.filtersList.endPeriod = '';
                this.filtersList.filterDays = ''
                this.filtersList.allPeriod = 0
            } else if(type == 'resource') {
                this.filtersList.resource.splice(
                    this.filtersList.resource.indexOf(
                        this.filtersList.resource.find((item) => item.name == name)), 1)
            }
            this.filterOrdersByTags(this.searchTerm);
            this.calcFiltersSize();
        },
        async watchInputBarCode(event){
            if(event.keyCode === 13){
                let stringSplit = this.barcode.split('-');
                let label = '';
                let operationNumber = '';

                if(stringSplit.length == 2){
                    label = stringSplit[0];
                    operationNumber = stringSplit[1];
                }

                let order = this.ordersMultiselectFiltered.length > 0
                    ? this.ordersMultiselectFiltered.find(item => item.order_no == label && item.op_no == operationNumber)
                    : this.ordersFiltered.find(item => item.order_no == label && item.op_no == operationNumber);

                if(!order) {
                    await (this.filtersSize === 0 ? this.getOrders('', this.barcode) : this.filterOrdersByTags(this.barcode));
                    this.getHiddenOrder = false;

                    order = this.ordersMultiselectFiltered.length > 0
                    ? this.ordersMultiselectFiltered.find(item => item.order_no == label && item.op_no == operationNumber)
                    : this.ordersFiltered.find(item => item.order_no == label && item.op_no == operationNumber);

                }

                if (!order) return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderNotFound'), 'danger');

                let orderByHourSelected = this.ordersMultiselectFiltered.length > 0
                    ? this.ordersMultiselectFiltered.find(item => item.selected && item.control_by_hour == 1)
                    : this.ordersFiltered.find(item => item.selected && item.control_by_hour == 1);

                if(orderByHourSelected) {
                    document.getElementById('input-barcode').blur();
                    return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderByHour'), 'danger');
                }
                if(order.disabled && order.control_by_hour == 0)
                    return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderDiffStatus'), 'danger');

                if(order.disabled && order.control_by_hour == 1)
                    return this.showToast(this.$t('RefuseStatus.Error'), 'FrownIcon', this.$t('Operator.ErrorOrderDiffType'), 'danger');

                if(!order.selected && order.order_no == label && order.op_no == operationNumber)
                    return this.selectOrderByBarCode(label, operationNumber);
            }
        },
        clearBarCode(){
            this.barcode = '';
        },
        selectOrderByBarCode(label, operationNumber) {
            if(!this.multiSelect){
                this.multiSelect = true;
            }

            let order = null;

            if(this.ordersMultiselectFiltered.length > 0) {
                order = this.ordersMultiselectFiltered.find(item => item.order_no == label && item.op_no == operationNumber);

            } else {
                order = this.orders.find(item => item.order_no == label && item.op_no == operationNumber);
            }

            if(order.selected) return;

            if(order) return this.createSelectedsList(order);

        },
        cardDateHourFormat(v) {
            let language = localStorage.getItem('language');
            let date = null;

            if(language == 'pt'){
                date = moment(v).format("DD-MM-YYYY HH:mm");
            } else if (language == 'en') {
                date = moment(v).format("MM-DD-YYYY HH:mm");
            }

            if(date){
                let stringSplit = date.split(' ');
                let newString = stringSplit[0] + ' ' + this.$t('Operator.At') + ' ' + stringSplit[1];
                return newString;
            }

            return '';
        },
        chipDateFormat(v){
            let language = localStorage.getItem('language');

            if(language == 'pt'){
                return moment(v).format("DD/MM/YYYY");
            } else if (language == 'en') {
                return moment(v).format("MM/DD/YYYY");
            }
            return '';
        },
        formatDateHourForFilter(date, formatDate = true){
            let language = localStorage.getItem('language');

            if(formatDate){
                if(language == 'pt'){
                    return moment(date).format("DD-MM-YYYY");
                } else if (language == 'en') {
                    return moment(date).format("MM-DD-YYYY");
                }
            } else {
                if(language == 'pt'){
                    return moment(date).format("HH:mm");
                } else if (language == 'en') {
                    return moment(date).format("HH:mm");
                }
            }

            return;
        },
        createSelectedsList(order){
            if(this.multiSelect) {
                    let iDOrdersFilteredByTag  = this.ordersFilteredByTag?.map(item => item.id);
                    let indexOrderFiltered = this.orders.findIndex(item => item.id == order.id)
                    this.orders[indexOrderFiltered].selected = !order.selected;
                    this.ordersFiltered = this.filtersSize > 0
                        ? this.orders.map(item => item).filter(item => iDOrdersFilteredByTag.indexOf(item.id) != -1)
                        : this.orders.map(item => item);

                    if(this.ordersFiltered.find(item => item.selected && item.control_by_hour == '1')) {
                        this.ordersFiltered = this.ordersFiltered.map(item => {
                            if(item.id != order.id){
                                item.disabled = true;
                            }
                            return item;
                        })

                        let orders = this.ordersFiltered;
                        orders.splice(indexOrderFiltered, 1);
                        orders.sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                        this.ordersFiltered = [order, ...orders];
                        this.selectedOrders = [order];
                        if(this.filtersSize > 0) this.ordersFilteredByTag = [order, ...orders];

                        return;
                    } else if (this.ordersFiltered.find(item => item.selected && item.control_by_hour == '0')){
                        let ordersQuantity = this.ordersFiltered.map(item => {

                            if(item.status != order.status || item.control_by_hour == '1'){
                                item.disabled = true;
                            }

                            return item;
                        });

                        let selectedOrders = ordersQuantity.filter(item => item.selected == true).sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                        let unselectedOrders = ordersQuantity.filter(item => item.selected == false).sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                        this.ordersMultiselectFiltered = [...selectedOrders, ...unselectedOrders]
                        this.selectedOrders = selectedOrders;
                        this.unselectedOrders = unselectedOrders;
                        this.ordersFiltered = [...selectedOrders, ...unselectedOrders];
                        if(this.filtersSize > 0) this.ordersFilteredByTag = [...selectedOrders, ...unselectedOrders];

                    } else if ((this.ordersFiltered.filter(item => item.selected).length == 0 && !this.getHiddenOrder) || (this.getHiddenOrder && [...this.ordersFiltered, ...this.ordersFilteredBackup].filter(item => item.selected).length == 0)) {
                        this.ordersFiltered = this.orders.map(item => {
                            item.disabled = false;

                            return item
                        })

                        this.selectedOrders = [];
                    }

                this.filterOrders();
            }
        },

        filterOrders(key = null) {
            if(key?.keyCode == 13 && this.filtersSize == 0) return this.getOrders('', this.searchTerm);
            if(key?.keyCode == 13 && this.filtersSize > 0) return this.filterOrdersByTags(this.searchTerm);

            if(this.multiSelect){
                if(!this.late || !this.surplus) {
                    this.ordersFiltered = this.ordersMultiselectFiltered.length > 0
                        ? this.ordersMultiselectFiltered
                        : this.ordersFilteredByTag.length > 0
                            ? this.ordersFilteredByTag
                            : this.orders;
                }

                if(this.searchTerm != ''){
                    this.ordersFiltered = this.ordersMultiselectFiltered.length > 0
                    ? this.ordersMultiselectFiltered.filter((o) =>
                        Object.keys(o).some((k) =>{
                            if(k == 'setup_start' || k == 'start_time' || k == 'end_time') {
                                if(this.formatDateHourForFilter(String(o[k])).includes(this.searchTerm.toLowerCase())
                                    || this.formatDateHourForFilter(String(o[k]), false).includes(this.searchTerm.toLowerCase()))
                                {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                return String(o[k]).toLowerCase().includes(this.searchTerm.toLowerCase());
                            }
                        })
                    )
                    : this.ordersFiltered.filter((o) =>
                        Object.keys(o).some((k) =>{
                            if(k == 'setup_start' || k == 'start_time' || k == 'end_time') {
                                if(this.formatDateHourForFilter(String(o[k])).includes(this.searchTerm.toLowerCase())
                                    || this.formatDateHourForFilter(String(o[k]), false).includes(this.searchTerm.toLowerCase()))
                                {
                                    return true;
                                } else {
                                    return false;
                                }
                            } else {
                                return String(o[k]).toLowerCase().includes(this.searchTerm.toLowerCase());
                            }

                        })
                    );
                } else if (this.searchTerm == '' &&
                    (this.ordersFiltered.find(item => item.selected) != undefined || this.ordersMultiselectFiltered.find(item => item.selected) != undefined)
                ) {
                    this.ordersFiltered = this.ordersMultiselectFiltered.length > 0 ? this.ordersMultiselectFiltered : this.ordersFiltered;
                } else if (this.searchTerm == '' &&
                    (this.ordersFiltered.find(item => item.selected) == undefined || this.ordersFiltered.find(item => item.selected) == undefined)
                ){
                    let orders = this.filtersSize > 0 ? this.ordersFilteredByTag : this.orders;
                    let ordersSortedByID = orders.sort((a, b) => Number(a.preactor_order_id) - Number(b.preactor_order_id));
                    let ordersSortedByDate = ordersSortedByID.sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                    this.ordersFiltered = ordersSortedByDate;
                }

                if(this.late)
                    return this.ordersFiltered = this.ordersFiltered.filter(el => new Date().getTime() > new Date(el.end_time).getTime());

                if(this.surplus)
                    return this.ordersFiltered = this.ordersFiltered.filter(el => Number(el.progress) > 100);

            } else {
                if(this.searchTerm != ''){

                    this.ordersFiltered = this.filtersSize > 0
                        ?   this.ordersFilteredByTag.filter((o) =>
                                Object.keys(o).some((k) =>{
                                    if(k == 'setup_start' || k == 'start_time' || k == 'end_time') {
                                        if(this.formatDateHourForFilter(String(o[k])).includes(this.searchTerm.toLowerCase())
                                            || this.formatDateHourForFilter(String(o[k]), false).includes(this.searchTerm.toLowerCase()))
                                        {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return String(o[k]).toLowerCase().includes(this.searchTerm.toLowerCase());
                                    }

                                })
                            )
                        :   this.orders.filter((o) =>
                                Object.keys(o).some((k) =>{
                                    if(k == 'setup_start' || k == 'start_time' || k == 'end_time') {
                                        if(this.formatDateHourForFilter(String(o[k])).includes(this.searchTerm.toLowerCase())
                                            || this.formatDateHourForFilter(String(o[k]), false).includes(this.searchTerm.toLowerCase()))
                                        {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        return String(o[k]).toLowerCase().includes(this.searchTerm.toLowerCase());
                                    }

                                })
                            )
                } else {
                    this.ordersFiltered = this.filtersSize == 0 ? this.orders : this.ordersFilteredByTag;
                }

                if(this.late)
                    return this.ordersFiltered = this.ordersFiltered.filter(el => new Date().getTime() > new Date(el.end_time).getTime());

                if(this.surplus)
                    return this.ordersFiltered = this.ordersFiltered.filter(el => Number(el.progress) > 100);
            }
        },
        async getOrders(status, search = '') {
            return new Promise((resolve, reject) => {
                this.loadingOrders = true
                let lastOrder = !this.firstLoading && this.ordersAntigas.length > 0 ? this.ordersAntigas.at(-1).id : '';
                this.$emit("atualizaShowOverlay", true);

                this.$http.get(`/api/${this.prefix}/operator/orders?site_guid=${this.currentSite.guid}`
                    + `${this.resource_id ? `&preactor_resource_id=${this.resource_id}`: ''}`
                    + `${status == 'moreOrders' && !search ? `&order_id=${lastOrder}` : ''}`
                    + `${search ? `&filter=${search}` : ''}`
                )
                    .then((response) => {
                        this.$emit("atualizaShowOverlay", false)
                        if((this.firstLoading || (status == 'moreOrders' && !search))) {
                            if(response.data.response != []) {
                                let orders = response.data.response.map(item => this.selectedOrders?.findIndex(order => order.id == item.id) == -1 ? {...item, selected: false, disabled: false} : {...item, selected: true, disabled: false});
                                let ordersTotais = [...this.ordersAntigas,...orders];
                                let ordersTotalSorted = [...this.ordersAntigas,...orders];
                                this.ordersAntigas = [...ordersTotais];

                                ordersTotalSorted.sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime());
                                this.orders = [...ordersTotalSorted];
                                this.ordersFiltered = [...ordersTotalSorted];

                                if(this.ordersMultiselectFiltered?.length > 0) {
                                    ordersTotalSorted.forEach(item => {
                                        if(this.selectedOrders?.length > 0) {
                                            item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                            : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                        }
                                    });

                                    let selectedOrders = [...this.selectedOrders.filter(item => item.selected == true).sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime())];
                                    let unselectedOrders = [...ordersTotalSorted.filter(item => item.selected == false).sort((a, b) => new Date(a.setup_start).getTime() - new Date(b.setup_start).getTime())];
                                    this.ordersMultiselectFiltered = [...selectedOrders, ...unselectedOrders];
                                }
                            }
                        } else if (search){
                            if(!this.getHiddenOrder){
                                this.ordersBackup = [...this.orders];
                                this.ordersFilteredBackup = [...this.orders];

                                this.backendOrdersList = response.data.response.map(order => {return{...order, selected: false, disabled: false}})

                                this.orders = [...this.orders, ...this.backendOrdersList].filter((item, index, array) => {
                                    return array.findIndex((elem) => {
                                        return elem.id === item.id
                                    }) === index;
                                })

                                this.orders.forEach(item => {
                                    if(this.selectedOrders?.length > 0) {
                                        item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                        : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                    }
                                });

                                this.ordersFiltered = [...this.orders, ...this.backendOrdersList].filter((item, index, array) => {
                                    return array.findIndex((elem) => {
                                        return elem.id === item.id
                                    }) === index;
                                })

                                this.ordersFiltered.forEach(item => {
                                    if(this.selectedOrders?.length > 0) {
                                        item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                        : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                    }
                                });

                                if(this.ordersMultiselectFiltered?.length > 0) {
                                    this.ordersMultiselectFiltered = [...this.ordersMultiselectFiltered, ...this.backendOrdersList].filter((item, index, array) => {
                                        return array.findIndex((elem) => {
                                            return elem.id === item.id
                                        }) === index;
                                    })

                                    this.ordersMultiselectFiltered.forEach(item => {
                                        if(this.selectedOrders?.length > 0) {
                                            item.disabled = this.selectedOrders[0].control_by_hour == 1 && item.id != this.selectedOrders[0].id ? true
                                            : this.selectedOrders[0].control_by_hour == 0 &&  ( item.control_by_hour != 0 || item.status != this.selectedOrders[0].status) ? true : false;
                                        }
                                    });
                                }
                            }

                            this.getHiddenOrder = search != '' ? true : false;
                        }

                        this.filterOrders();
                        this.firstLoading = false
                        this.loadingOrders = false
                    }).then(()=> {
                        this.verificaFormatacao();
                        resolve();
                    })
                    .catch(() =>{
                        this.$emit("atualizaShowOverlay", false);
                        reject();
                    })
            })
        },
        getStyle(component, order) {
            if (component === 'header')
                return `background: ${order.color} !important;`;
            if (component === 'body')
                return `border-color: ${order.color} !important;`;
            if (component === 'progress')
                return `background-color: ${order.color} !important;`;
            if (component === 'alert')
                return `border: 1px solid ${order.color}; color: ${order.color};`
            if (component === 'alert-icon')
                return order.color ? order.color : '#263238'
        },
        backResources() {
            this.$emit('back-resources');
        },
        cleanAll() {
            this.$swal({
                title: this.$t('IntegrationPage.attention'),
                text: this.$t('Operator.CleanAlert'),
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: this.$t('MySites.cancel'),
                confirmButtonText: this.$t('Operator.Clean'),
                customClass: {
                    container: 'swal-conection',
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                this.$emit("atualizaShowOverlay", true);
                if (result.value) {

                }
                this.$emit("atualizaShowOverlay", false);
            })
        },
        focusInputBarcode() {
            this.$refs.barcode.focus();
        },
        showToast(title, icon, text, variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                title: title,
                icon: icon,
                text: text,
                variant,
                },
            })
        },
        showMessageInterval(){
            setTimeout(() =>{
                this.showMessage = true;
            }, 150)
        }
    }
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
    div ::v-deep {
        .b-overlay {
            .bg-light {
                background-color: $theme-dark-body-bg !important;
            }
        }
    }
}

.icon-loading {
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.filter-with-size {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600 !important;
    font-size: 14px;
    color: #fff !important;
    width: 100%;
    background-color: #974900 !important;
    border-color: #974900 !important;
    height: 37px;

    &:hover {
        background-color: #974900 !important;
    }

    &:active {
        background-color: #974900 !important;
    }

    &:focus {
        background-color: #974900 !important;
    }

    .circle-filters-size{
        height: 18px;
        width: 18px;
        background: #fff;
        border-radius: 50%;
        margin-left: 8.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            font-weight: 600;
            font-size: 10px;
            color: #974900;
        }
    }

    .icon-filter-orders  {
        margin-right: 6.5px;

        path {
            fill: #fff;
        }
    }
  }


.barcode {
    width: 1%;
    opacity: 0;
}

.btn-add-filter {
    .icon-filter-orders {
        margin-right: 7px;
    }
}

.detail-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    color: #4C4541;
    max-width: 100%;
    text-align: left;
}

.btn-back {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    color: #974900;
}

.orders-filtro {
  display: flex;
  align-items: center;
  margin: 10px 0;
  overflow: hidden;
  padding-left: 0 !important;

  .limpar-filtros-btn {
    background: #974900;
    border-radius: 5px;
    white-space: nowrap;
    height: fit-content;
    border: 1px solid #974900;
    padding: 3px 14px;
    color: #fff;
    margin-right: 10px;
    font: 600 12px/20px initial;
    &:hover {
      background: #F7F3EF;
      color: #974900;
    }
  }
  .filtros-area {
    display: flex;
    overflow: auto;
    padding-right: 28px;
    margin-right: -28px;
    &::-webkit-scrollbar {
      height: 5px;               /* width of the entire scrollbar */
      border-radius: 50%;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;        /* color of the tracking area */
      margin-right: 28px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }

    .slick-slide {
        gap: 15px;
        padding: 0 5px;

        &::-webkit-scrollbar {
            width: 0 !important;
        }
    }

    .filtro-item {
      display: flex;
      font: 600 12px/20px initial;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      padding: 6px 14px;
      margin: 0 5px;
      background-color: #FFDBC4;
      color: #974900;
      border-radius: 8px;
      svg {
        margin-left: 12px;
      }
      &:hover {
          cursor: pointer;
        }
    }

    .filtro-item:first-child(){
        margin-left: 0px;
    }
  }
}

.graph-buttons-resources {
    width: calc(100% + 32px);
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;

    .btn-graph-resources {
        font-size: 16px;
        border: none;
    }

    .btn-active {
        border-bottom: 2px solid #974900;
        font-weight: 600;
        width: 100%;
        background-color: transparent !important;
        color: #974900 !important;
        border-radius: 0;
    }

    .btn-disabled {
        border-bottom: 2px solid #cfc4be;
        width: 100%;
        background-color: transparent !important;
        color: #cfc4be !important;
        border-radius: 0;
    }
}

.resource-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4C4541;
}

.total-results {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #998F8A;
}

.badge-status {
    padding: 0.4rem 0.5rem;
    height: 28px;
    background-color: #FFFFFF;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
}

.div-checkbox {
    display: flex;
    align-items: center;
    gap: 12px;
}

.text-checkbox {
    font-weight: 400;
    font-size: 14px;
    color: #4C4541;
}

.custom-control-input:checked~.custom-control-label::before {
    background-color: #974900 !important;
    border-color: #974900 !important;
}

.btn-template {
    height: 38px;
    border: 1px solid #974900 !important;
    color: #974900 !important;
    background: #FFF !important;
    font-weight: 600;
    font-size: 14px;

    &:focus {
        background: #FFEDE2 !important;
    }

    &:hover {
        box-shadow: none !important;
        background: #FFEDE2 !important;
    }

    &.block {
        background: #974900 !important;
        color: #FFF !important;
    }
}

.card-orders {
    width: 100%;
    border-radius: 6px 6px 6px 6px;
    background: #fff !important;
    padding: 0;
    border-color: transparent !important;
    &:hover {
        box-shadow: none !important;
    }

    &:focus {
        background: #fff !important;
    }
    .col-item-card{
        height:100%;
        border-radius: 6px;

        .card-orders-header {
            padding: 0.8rem !important;
            display: flex;
            align-items: center;
            width: 100%;
            height: 80px;
            border-radius: 4px 4px 0px 0px;
            justify-content: flex-start !important;

            .order-name {
                font-weight: 600;
                font-size: 18px;
                color: #FFFFFF;
                white-space: break-spaces;
            }

            .resource-name-sub-title {
                font-weight: 400;
                font-size: 12px;
                color: #FFFFFF;
                margin-top: 6px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .card-orders-body {
            padding: 0px 12px 0px;
        }
    }

    .text-label {
        font-weight: 400;
        font-size: 14px;
        color: #998F8A;
    }

    .percent {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #4C4541;
    }

    .progress {
        background-color: #F7F3EF !important;
    }
}

.alert-orders {
    cursor: pointer;
    text-align: left;
    padding: 1.2rem;
    background: #E3F2FD !important;
    border: 1px solid #42A5F5 !important;
    color: #0D47A1 !important;

    .close {
        padding: 1rem !important;
        // background-color: #4C4541 !important;
        color: #4C4541 !important;
    }

    .esc {
        margin-left: 8px;
        margin-right: 10px;
        padding: 8px 3px 8px 4px;
        border: 1px solid #0D47A1;
        border-radius: 6px;
        font-weight: 600;
        font-size: 12px;
    }
}

.btn-remove {
    font-weight: 600;
    margin-top: 1rem;
    width: 100%;
}

.col-selected-items {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    flex-wrap: nowrap;
    gap: 15px;
    margin-bottom: 1.5rem;
    align-items: flex-end;

    &::-webkit-scrollbar {
        width: 0 !important;
    }
}

.col-scrollable {
    overflow: auto;
    height: 200px;
    padding-left: 0px;

    &::-webkit-scrollbar {
        border-radius: 10px;
        width: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background: #CFC4BE;
        border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
        border-radius: 10px;
    }
}

.new-note {
    position: fixed;
    bottom: 20px;
    right: 82px;
    z-index: 99;
    background-color: #974900 !important;
    font-size: 12px;
    border: none !important;
    outline: none;
    color: white;
    height: 36px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
    border-radius: 5px;

    &:focus {
        background-color: #974900 !important;
    }
    &:hover {
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2) !important;
    }
}

.selected-filter {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 8px 6px 12px;
    gap: 10px;
    height: 32px;
    background: #FFDBC4 !important;
    border-radius: 8px;
    line-height: 20px;

    font-weight: 600;
    font-size: 12px;
    color: #974900 !important;
    border: none !important;

    &:hover {
        box-shadow: none !important;
    }

    &:focus {
        background: #FFDBC4 !important;
    }
}

.btn-clean-fixed {
    height: 32px;
    background-color: #974900 !important;
    border: none !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 12px;

    &:focus {
        background-color: #974900 !important;
    }
}

.card-order-status {
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
    margin-left: -12px;
    margin-right: -12px;
    height: 38px;
    padding: 8px;
    font-weight: 600;
    font-size: 14px;
    border-left: none !important;
    border-right: none !important;

    span {
        height: 16px;
    }
}

.swal-conection {
    .swal2-modal {
        padding: 0 !important;
        border-radius: 6px;
        width: 710px;

        .swal2-header {
            background-color: #D32F2F;
            padding: 20px 16px;
            border-radius: 6px 6px 0px 0px;
            flex-direction: row;
            justify-content: left;

            .swal2-title {
                color: #ffff;
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                margin: 0;
            }

            .swal2-close {
                border-radius: 6px;
                width: 34px;
                background-color: #ffff;
                height: 34px;
                top: -5px;
                position: absolute;
                right: -5px;
            }
        }

        .swal2-content {
            padding: 20px 16px;

            .swal2-html-container {
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #4C4541;
                display: flex !important;

            }
        }

        .swal2-actions {
            padding: 0 16px 20px 16px;
            display: flex;
            justify-content: right;

            .swal2-confirm {
                margin-left: 10px !important;
                order: 2;
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                color: #ffff;
                border-color: #974900 !important;
                background-color: #974900 !important;
                border-radius: 5px;

                &:hover {
                    box-shadow: none !important;
                }
            }

            .swal2-cancel {
                order: 1;
                font-weight: 600;
                font-size: 17px;
                line-height: 20px;
                color: #974900 !important;
                border-radius: 5px;
                border-color: transparent !important;

                &:hover {
                    background-color: #ffede1 !important;
                }
            }
        }
    }
}

.div-detail-orders {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.detail-orders {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: .5rem;
}

.slide-fade-enter-active {
    transition: all 0.4s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}

.col-cards {
    width: 100%;
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: 1px solid #CFC4BE;
    margin-top: 16px;
    border-radius: 5px;
    width: 100%;

    span {
        display: flex;
        align-items: baseline;

        h3 {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #998F8A;
        }

        svg {
            margin-right: 16.13px;
        }
    }
}

@media (max-width:480px) {
    .alert-orders {
        line-height: 25px;
        height: 85px;
    }

    .btn-resource {
        flex-direction: column;
        align-items: start;
        height: 84px !important;
        padding: 18px;
    }

    .badge-status {
        padding: 3px 9px;
        height: 18px;
        font-size: 12px;
    }

    .resource-name {
        order: 1;
    }

    .btn-add-filter {
        font-size: 12px;
        height: 28px;
    }

    .text-checkbox {
        font-size: 12px !important;
    }

    .div-checkbox {
        .custom-switch .custom-control-label::before {
            height: 1.2rem;
            width: 2.5rem;
        }

        .custom-switch .custom-control-label::after {
            top: 2px;
            width: 0.8rem;
            height: 0.8rem;
        }
    }

    .div-selected-items {
        display: none !important;
    }

    .detail-orders {
        align-items: center !important;
    }

    .card-orders {
        .percent {
            font-size: 14px !important;
        }

        .text-label {
            font-size: 12px !important;
        }
    }

    .btn-template {
        font-size: 12px !important;
        height: 28px !important;
        padding: 0px;
    }
}

@media (max-width:991px) {
    .row-btn-resource {
        margin-right: -21px;
        margin-left: -21px;
    }

    .btn-resource {
        border-radius: 0;
    }

    .col-actions {
        margin-top: 10rem !important;
    }

    .col-cards {
        padding: 0px !important;
    }

    .row-cards {
        margin-left: -22px;
        margin-right: -22px;
    }
    .col-item-card{
        border-radius: 0px !important;
    }
    .card-orders-header {
        border-radius: 0px !important;
    }

    .card-orders-body {
        border-radius: 0px !important;
    }
}
</style>
