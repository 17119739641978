<template>
    <b-modal id="modal-add-filter" shadow right bg-variant="white" backdrop size="lg"
        centered>

        <template #modal-header>
            <div class="d-flex align-items-center justify-content-start w-100 title-modal">
                <span class="modal-title">
                    {{$t('FilterDays.Filter')}}
                </span>
            </div>
            <button 
                type="button" 
                aria-label="Close" 
                class="close close-mobile" 
                @click="$bvModal.hide('modal-add-filter')"
            >×</button>
        </template>
        <div class="div-selected-items">
            <b-col 
                cols="12" 
                v-if="(filterStatus && selectedStatusFilters.length > 0) 
                    || (filterWorkcenter && selectedWorkcentersFilters.length > 0)"
            >
                <span class="modal-add-text">{{ $t('Operator.SelectedFilters') }}</span>
            </b-col>

            <VueSlickCarousel
                    v-bind="settings"
                    :key="`carousel-${reRenderCarousel}`"
            >
                <b-button class="selected-filter"
                    v-for="(selected,index) in (filterStatus ? selectedStatusFilters : selectedWorkcentersFilters)" :key="index">
                    <span>{{ selected.name }}</span>
                    <remove-icon class="cursor-pointer"
                        @click="filterStatus ? managerStatusSelectedFilters(selected) : managerWorkcenterSelectedFilters(selected)" />
                </b-button>
        
            </VueSlickCarousel>
        </div>

        <b-row class="m-0 content-modal-resource">
            <b-col cols="12" sm="4" md="4" lg="4" xl="4">
                <div class="div-type-filter">
                    <span class="modal-add-text">{{ $t('Operator.Type') }}</span>
                    <div class="div-type" @click="toggleFilter">
                        <div class="filter-type" :class="{ 'active': filterStatus }">
                            <status-icon />
                        </div>
                        <span>Status</span>
                    </div>

                    <div class="div-type mt-2" @click="toggleFilter">
                        <div class="filter-type" :class="{ 'active': filterWorkcenter }">
                            <workcenter-icon />
                        </div>
                        <span>{{ $t('Operator.Workcenters') }}</span>
                    </div>
                </div>

                <div class="div-type-filter-mobile">
                    <b-form inline>
                        <label class="col-2 mb-0 pl-0" for="inline-form-custom-select-pref">
                            {{ $t('Operator.Type') }}
                        </label>
                        <b-form-select id="inline-form-custom-select-pref" class="col-10"
                            :options="[{ text: 'Status', value: 1 }, { text: $t('Operator.Workcenters'), value: 2 },]"
                            :value="1" @change="toggleFilter">
                        </b-form-select>
                    </b-form>
                </div>
            </b-col>

            <b-col class="items-list-context mt-2 mt-sm-0" cols="12" sm="8" md="8" lg="8" xl="8" v-if="filterStatus">
                <span class="modal-add-text">{{ $t('Operator.Select') }}</span>

                <div class="status-list" v-for="(status,index) in sequencingStatus" :class="{ 'active': status.selected }" :key="index">
                    <span>{{ status.name }}</span>
                    <b-form-checkbox v-model="status.selected" v-on:change="managerStatusSelectedFilters(status)" />
                </div>
            </b-col>

            <b-col class="items-list-context mt-2 mt-sm-0" cols="12" sm="8" md="8" lg="8" xl="8"  v-else >
                <span class="modal-add-text">{{ $t('Operator.Select') }}</span>

                <b-form-input :placeholder="$t('Operator.SearchWorkcenters')" type="text" class="d-inline-block mt-1" @input="filterByInput($event)" />

                <b-row class="mt-1 mb-1">
                    <b-col cols="12">
                        <b-button v-for="(obj,index) in initialWorkCenters" :key="index" class="btn-initial-letter"
                            :class="{ 'selected': obj.selected }" @click="filterByLetter(obj)">
                            {{ obj.char }}
                        </b-button>
                    </b-col>
                </b-row>

                <b-col class="col-scrollable">
                    <div class="status-list" v-for="(workcenter,index) in workcentersFiltred" :key="index"
                        :class="{ 'active': workcenter.selected }">
                        <span>{{ workcenter.name }}</span>
                        <b-form-checkbox v-model="workcenter.selected"
                            v-on:change="managerWorkcenterSelectedFilters(workcenter)" />
                    </div>

                    <div class="not-found" v-if="workcentersFiltred.length == 0">
                        <span>
                            <SearchIcon />
                            <h3>{{ $t('Operator.NoResultsFound') }}</h3>
                        </span>
                    </div>
                </b-col>
            </b-col>

            <b-col cols="12" class="col-actions">
                <b-button 
                    class="btn-template" 
                    @click="cleanFilter" 
                    :disabled="!(selectedStatusFilters.length > 0 || selectedWorkcentersFilters.length > 0)"
                >
                    {{ $t('FilterDays.Clean') }}
                </b-button>

                <b-button 
                    class="btn-template block"
                    @click="applyFilter"
                    :disabled="!flagButtonApply"
                >
                    {{ $t('FilterDays.Submit') }}
                </b-button>
            </b-col>
        </b-row>
    </b-modal>
</template>

<script>
import { BCol, BRow, BButton, BFormInput, BModal, BFormCheckbox, BForm, BFormSelect } from 'bootstrap-vue'
import AddIcon from '@/assets/images/icons/icon-add.svg'
import RemoveIcon from '@/assets/images/icons/remove.svg'
import StatusIcon from '@/assets/images/icons/icon-status.svg'
import WorkcenterIcon from '@/assets/images/icons/workcenter-icon.svg'
import SearchIcon from '@/assets/images/pages/Search.svg'
import VueSlickCarousel from 'slick-vuejs'
import 'slick-vuejs/dist/slick-vuejs.css'
import 'slick-vuejs/dist/slick-vuejs-theme.css'

export default {
    components: {
        BCol,
        BRow,
        BButton,
        BFormInput,
        AddIcon,
        BModal,
        RemoveIcon,
        StatusIcon,
        WorkcenterIcon,
        SearchIcon,
        BFormCheckbox,
        BForm,
        BFormSelect,
        VueSlickCarousel
    },
    props: ["filtersList", "calcFilters"],
    created() {
        this.getContext();
    },
    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            if(modalId == 'modal-add-filter'){
                this.filterStatus = true;
                this.filterWorkcenter = false;

                this.selectedStatusFilters = [...this.filtersList.status];
                this.selectedWorkcentersFilters = [...this.filtersList.workcenter];

                let statusName = this.selectedStatusFilters.map(item => item.name)
                let workcentersName = this.selectedWorkcentersFilters.map(item => item.name)

                this.sequencingStatus.forEach((item, i) => {
                    if(statusName.indexOf(item.name) != -1){
                        this.sequencingStatus[i].selected = !false;
                    }
                })

                this.workcenters.forEach((item, i) => {
                    if(workcentersName.indexOf(item.name) != -1){
                        this.workcenters[i].selected = !false;
                    }
                })

                this.workcentersFiltred = this.workcenters.map(item => item);
                
            }
        })
        this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
            if(modalId == 'modal-add-filter'){
                this.flagButtonApply = false;

                this.sequencingStatus = this.sequencingStatus.map(item => {
                    return {...item, selected: false}
                })
                this.workcenters = this.workcenters.map(item => {
                    return {...item, selected: false}
                })
                
            }
        })
    },
    data() {
        return {
            settings:{
                arrows: false,
                infinite: false,
                variableWidth: true,
                swipeToSlide: true,
                centerMode: false,
            },
            reRenderCarousel: 0,
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            resources: [],
            statusList: [],
            filterStatus: true,
            filterWorkcenter: false,
            selectedStatusFilters: [],
            selectedWorkcentersFilters: [],
            sequencingStatus: [],
            workcenters: [],
            initialWorkCenters: [],
            filtersListLocal: {
                workcenter: [],
                status: [],
            },
            resourceDetails: false,
            resourceSelected: null,
            firstLoading: true,
            flagButtonApply: false,
            workcentersFiltred: [],
            workcentersFiltredLetter:[]
        }
    },
    methods: {
        getContext(){
            this.$http.get(`api/${this.prefix}/operator/resources/context?site_guid=${this.currentSite.guid}`)
                .then(res => {
                    res.data.workcenters.forEach(item => {
                        this.workcenters.push({name: item, selected: false})
                        this.workcentersFiltred.push({name: item, selected: false})
                    });
                    
                    res.data.status.forEach(item => this.sequencingStatus.push({name: item, selected: false}))

                    this.workcenters.forEach(item => {
                        let letter = item.name.charAt(0);
                        let obj = { char: letter, selected: false };

                        if (!this.initialWorkCenters.find(x => x.char == letter))
                            this.initialWorkCenters.push(obj);
                    });

                    this.initialWorkCenters = this.initialWorkCenters.sort((a, b) => {
                        if (a.char > b.char) {
                            return 1;
                        }
                        if (a.char < b.char) {
                            return -1;
                        }

                        return 0;
                    });
                })
        },
        filterByInput(input){
            let letterSelected = this.initialWorkCenters.find(item => item.selected == true) != undefined ? true : false;

            if (letterSelected) {
                this.workcentersFiltred = this.workcentersFiltredLetter.filter(item => item.name.toLowerCase().indexOf(input.toLowerCase()) != -1);
            } else {
                let workcenterFiltred = this.workcenters.filter(item => item.name.toLowerCase().indexOf(input.toLowerCase()) != -1);
                this.workcentersFiltred = workcenterFiltred;
            }
        },
        filterByLetter(letter){
            let chars = this.initialWorkCenters.map(item => item.char);
            let indexInitial = chars.indexOf(letter.char);

            this.initialWorkCenters = this.initialWorkCenters.map(item => {
                return {...item, selected: false }
            });

            this.initialWorkCenters[indexInitial] = {...letter, selected: !letter.selected };

            let workcenters = this.workcenters.map(item => item);

            if(!letter.selected){
                this.workcentersFiltredLetter = workcenters.filter(item => item.name[0] == letter.char)
                this.workcentersFiltred = workcenters.filter(item => item.name[0] == letter.char)
            } else {
                this.workcentersFiltredLetter = [];
                this.workcentersFiltred = workcenters
            }
        },
        applyFilter() {
            this.filtersList.workcenter = [...this.selectedWorkcentersFilters];
            this.filtersList.status = [...this.selectedStatusFilters];
            this.$emit('update:filtersList', this.filtersList);
            this.$emit('filterResources');
            this.calcFilters();
            this.$bvModal.hide('modal-add-filter');
        },
        toggleFilter() {
            this.filterStatus = !this.filterStatus;
            this.filterWorkcenter = !this.filterWorkcenter;

            this.reRenderCarousel++;
        },
        managerStatusSelectedFilters(status) {
            let selectedStatusName = this.selectedStatusFilters.map(item => item.name)
            let checkedStatusName = this.sequencingStatus.map(item => item.name);
            let indexSelectedStatusName = selectedStatusName.indexOf(status.name);
            let indexCheckedStatusName = checkedStatusName.indexOf(status.name);

            if (indexSelectedStatusName > -1 && this.selectedStatusFilters.length > 0) {
                this.selectedStatusFilters.splice(indexSelectedStatusName, 1);
                this.sequencingStatus[indexCheckedStatusName].selected = false;
                this.flagButtonApply = true;
            } else {
                this.selectedStatusFilters.push(status);
                this.flagButtonApply = true;
            }
            this.reRenderCarousel++;
        },
        managerWorkcenterSelectedFilters(workcenter) {
            let selectedWorkcentersName = this.selectedWorkcentersFilters.map(item => item.name)
            let checkedWorkcentersName = this.workcenters.map(item => item.name);
            let indexSelectedWorkcentersName = selectedWorkcentersName.indexOf(workcenter.name);
            let indexCheckedWorkcentersName = checkedWorkcentersName.indexOf(workcenter.name);

            if (indexSelectedWorkcentersName > -1 && this.selectedWorkcentersFilters.length > 0) {
                this.selectedWorkcentersFilters.splice(indexSelectedWorkcentersName, 1);
                this.workcenters[indexCheckedWorkcentersName].selected = false;
                this.flagButtonApply = true;
            } else {
                this.selectedWorkcentersFilters.push(workcenter);
                this.flagButtonApply = true;
            }

            this.reRenderCarousel++;
        },
        cleanFilter() {
            this.selectedStatusFilters = [];
            this.selectedWorkcentersFilters = [];
            this.filterStatus = true;
            this.filterWorkcenter = false;

            this.initialWorkCenters.forEach(item => {
                item.selected = false;
            });

            this.workcenters.forEach(item => {
                item.selected = false;
            });

            this.sequencingStatus.forEach(item => {
                item.selected = false;
            });

            this.filtersList.workcenter = [];
            this.filtersList.status = []
            this.$emit('update:filtersList', this.filtersList);
            this.calcFilters(true);
            this.$emit('filterResources');
            this.$bvModal.hide('modal-add-filter');
        }
    }
}
</script>

<style lang="scss">

@media(max-width:480px){
    #modal-add-filter{
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-height: 100vh;

        .col-scrollable {
            &::-webkit-scrollbar {
                border-radius: 10px;
                width: 5px;
            }

            &::-webkit-scrollbar-thumb {
                background: transparent !important;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-track {
                background: transparent !important;
                border-radius: 10px;
            }
        }

        .btn-template {
            height: 38px !important;
            border: none !important
        }

        .col-actions {
            position: absolute;
            bottom: 10px;
            left: 0;
        }

        .items-list-context {
            height: 100vh;
            overflow: scroll;
        }

        .div-type-filter-mobile {
            height: 8vh;
            margin-bottom: 0px !important;
        }

        .content-modal-resource {
            position: relative;
        }

        .modal-content {
            height: 100vh;
            width: 100vw;
        }

        .modal-body {
            overflow: hidden !important;
        }
        .modal-dialog {
            margin: 0 !important
        }
        
        .btn-template {
            height: 38px !important;
            border: none !important
        }

        .col-actions {
            background: #fff;
            position: fixed;
            height: 10vh;
            bottom: 0px;
            left: 0;
            display: flex;
            align-items: center;
        }

        .content-modal-filter-orders {
            position: relative;
            // height: 120vh;
        }

        .modal-header {
            height: 8vh;
            background: #fff !important;
            .close {
                left: 0 !important;
                transform: none !important;
                background-color: #f8f8f8 !important;
                top: 0 !important;
                box-shadow: none !important;
                padding: 0.8rem 1.4rem !important
            }
            .close.close-mobile {
                background-color: #fff !important;
            }
        }
    }
}

#modal-add-filter {

    .modal-footer {
        display: none;
    }
    
    .modal-content {
        
        .modal-header {
            padding: 20px 35px;
    
            .modal-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #4C4541;
            }
    
            .close {
                transform: none !important;
                transition: none !important;
                top: -12px;
                left: 15px;
            }
        }
    
        .add-row {
            svg {
                position: absolute;
            }
    
            .btn {
                background: #974900 !important;
                border-radius: 5px;
                border: none;
                width: 10px;
                display: flex;
                justify-content: center;
                height: 36px;
            }
        }
    
        .remove-row {
            svg {
                position: absolute;
            }
    
            .btn {
                background: #D32F2F !important;
                border-radius: 5px;
                border: none;
                width: 10px;
                display: flex;
                justify-content: center;
                height: 36px;
            }
        }
    
        .save-button {
            .btn {
                background-color: #974900 !important;
                border-radius: 5px;
                border: none;
                font-size: 17px;
                width: 100px;
                height: 45px;
            }
        }
    
        .modal-add-text {
            font-weight: 600;
            font-size: 14px;
            color: #998F8A;
        }
    
        .selected-filter {
            margin-top: 8px;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 8px 6px 12px;
            gap: 10px;
            height: 32px;
            background: #FFDBC4 !important;
            border-radius: 8px;
            line-height: 20px;
    
            font-weight: 600;
            font-size: 12px;
            color: #974900 !important;
            border: none !important;
    
            &:hover {
                box-shadow: none !important;
            }
    
            .cursor-pointer{
                margin-left: 12px;
            }
        }
    
        .slick-slide {
            gap: 15px;
            padding: 0 5px;
            margin-bottom: 1.5rem;
    
            &::-webkit-scrollbar {
                width: 0 !important;
            }
        }
    
        .filter-type {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            width: 38px;
            height: 38px;
            background: #FFEDE2;
            box-shadow: 0px 3px 6px rgba(72, 32, 3, 0.4);
            border-radius: 6px;
    
            &.active {
                background: #974900;
    
                svg {
                    filter: brightness(2.5);
                }
    
            }
        }
    
        .div-type {
            margin-top: 1rem;
            display: flex;
            align-items: center;
            gap: 10px;
            font-weight: 600;
            font-size: 14px;
            color: #974900;
            cursor: pointer;
        }
    
        .status-list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #FCFCFC;
            border: 1px solid #998F8A;
            border-radius: 6px;
            height: 52px;
            margin-top: 0.8rem;
            padding: 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #4C4541;
    
            &.active {
                background: #FFEDE2;
                color: #974900;
                border-color: #974900;
            }
        }
    
        .custom-control-input:checked~.custom-control-label::before {
            background-color: #974900 !important;
            border-color: #974900 !important;
        }
    
        .btn-initial-letter {
            border: none !important;
            background: #FFF !important;
            color: #974900 !important;
            margin-right: 10px;
            width: 37px;
            height: 28px;
            padding: 0px 0px !important;
            font-weight: 600;
    
            &:hover {
                box-shadow: none !important;
                background: #FFEDE2 !important;
            }
    
            &.selected {
                background-color: #974900 !important;
                color: #FFF !important;
            }
        }
    
        .col-scrollable {
            overflow: auto;
            height: 200px;
            padding-left: 0px;
    
            &::-webkit-scrollbar {
                border-radius: 10px;
                width: 5px;
            }
    
            &::-webkit-scrollbar-thumb {
                background: #CFC4BE;
                border-radius: 10px;
            }
    
            &::-webkit-scrollbar-track {
                border-radius: 10px;
            }
        }
    
        .col-actions {
            margin-top: 3rem;
            display: flex;
            justify-content: end;
            gap: 15px;
        }
    
        .btn-template {
            height: 38px;
            border: 1px solid #974900 !important;
            color: #974900 !important;
            background: #FFF !important;
            font-weight: 600;
            font-size: 14px;
    
            &:disabled{
                &:hover{
                    background: transparent !important;
                }
                &.block:hover{
                    background: #974900 !important
                }
            }
            &:hover {
                box-shadow: none !important;
                background: #FFEDE2 !important;
            }
    
            &.block {
                background: #974900 !important;
                color: #FFF !important;
            }
        }
    
        .div-type-filter-mobile {
            display: none;
        }
    }
}
</style>