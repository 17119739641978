<template>
    <div>
        <modal-filter
            :filtersList.sync="filtersList"
            :calcFilters="calcFiltersSize"
            @filterResources="filterResources"
        />
        <div v-if="!resourceDetails">
            <b-row>
                <b-col cols="12" lg="9" xl="10">
                    <b-form-group>
                        <b-form-input v-model="searchTermResource" :placeholder="$t('Operator.SearchResources')" id="resource-filter-input"
                            type="text" class="d-inline-block" @keyup="filterResource($event)" @focus="ActiveResourceInputFocus = true"
                            @blur="ActiveResourceInputFocus = false"/>
                    </b-form-group>
                </b-col>

                <b-col cols="12" lg="3" xl="2">
                <b-button class="d-none d-sm-block btn-add-filter" v-b-modal.modal-add-filter>
                    <add-icon class="icon-add-filter-orders" />
                    <span>
                        {{ $t('Operator.AddFilter') }}
                    </span>
                </b-button>
                <b-button :class="`${filtersSize > 0 ? 'filter-with-size' : 'btn-add-filter'} d-sm-none `" v-b-modal.modal-add-filter>
                    <IconFilter class="icon-filter-orders"/>
                    {{$t('Operator.Filter')}}
                    <div class="circle-filters-size" v-if="filtersSize > 0">
                        <span>{{ filtersSize }}</span>
                    </div>
                </b-button>
                </b-col>

                <b-col class="col-12 resource-orders-filtro" v-if="filtersList.workcenter.length > 0 || filtersList.status.length > 0">
                    <button type="button" @click="clearFilters" class="limpar-filtros-btn d-none d-sm-block">{{$t('Operator.CleanFilter')}}</button>
                        <VueSlickCarousel
                            v-bind="settings"
                            :key="`carousel-filters-${reRenderCarousel}`"
                            class="filtros-area"
                        >
                            <div class="filtro-item" v-for="workcenter in filtersList.workcenter" :key="'workcenter-filter-item' + workcenter.name">
                                {{workcenter.name}}<imgDeleteIcon @click="deleteFilteredItem('workcenter', workcenter.name)" />
                            </div>
                            <div class="filtro-item" v-for="status in filtersList.status" :key="'status-filter-item' + status.name">
                                {{status.name}}<imgDeleteIcon @click="deleteFilteredItem('status', status.name)" />
                            </div>
                        </VueSlickCarousel>
                </b-col>

                <b-col cols="12" class="mt-1 d-flex justify-content-between align-items-center">
                    <span class="resource-title">{{ $t('Operator.Resources') }}</span>
                    <span class="total-results">
                        {{ resourcesFiltered.length }}
                        <span v-if="resourcesFiltered.length === 1">{{ $t('Operator.Result') }}</span>
                        <span v-else>{{ $t('Operator.Results') }}</span>
                    </span>
                </b-col>
            </b-row>

            <div class="not-found" v-if="resourcesFiltered.length == 0">
                <span>
                    <SearchIcon />
                    <h3>{{ $t('Operator.NoResultsFound') }}</h3>
                </span>
            </div>

            <b-row class="row-btn-resource">
                <b-col cols="12" lg="6" xl="6" v-for="(resource, index) in resourcesFiltered"
                    :key="`${resource.id}-${index}`" class="mt-2 pl-0 pr-0 pl-lg-1 pr-lg-1"
                    @click="toResourceDetails(resource)">
                    <b-button :style="`background-color:${resource.color} !important;`" class="btn-resource">
                        <span class="resource-name">{{ resource.name }}</span>
                        <b-badge variant="light" class="badge-status" :style="`color: ${resource.color}`"
                        >
                            {{ resource.status }}
                        </b-badge>
                    </b-button>
                </b-col>
            </b-row>
        </div>

        <orders v-else :resource="resourceSelected" @back-resources="backResource"
        @atualizaShowOverlay="(value) => atualizaShowOverlay(value)" />
    </div>
</template>

<script>
import { BCol, BRow, BButton, BFormGroup, BFormInput, BBadge, BForm } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import AddIcon from '@/assets/images/icons/icon-add.svg'
import IconFilter from '@/assets/images/pages/filter-icon.svg'
import Orders from './Orders.vue'
import SearchIcon from '@/assets/images/pages/Search.svg'
import LoadingIcon from '@/assets/images/icons/loading.svg'
import ModalFilter from './ModalResourceFIlter.vue'
import imgDeleteIcon from '@/assets/images/icons/delete-icon.svg'
import VueSlickCarousel from 'slick-vuejs'
import 'slick-vuejs/dist/slick-vuejs.css'
import 'slick-vuejs/dist/slick-vuejs-theme.css'

export default {
    components: {
        BCol,
        BRow,
        BButton,
        BFormGroup,
        BFormInput,
        BBadge,
        AddIcon,
        IconFilter,
        Orders,
        BForm,
        SearchIcon,
        LoadingIcon,
        ModalFilter,
        imgDeleteIcon,
        VueSlickCarousel
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            currentSite: JSON.parse(localStorage.getItem('currentSite')),
            prefix: localStorage.getItem('prefix') || this.$router.currentRoute.params.prefix,
            settings:{
                arrows: false,
                infinite: false,
                variableWidth: true,
                swipeToSlide: true,
                centerMode: false,
            },
            reRenderCarousel: 0,
            resources: [],
            statusList: [],
            resourcesFiltered: [],
            searchTermResource: '',
            initialWorkCenters: [],
            resourceDetails: false,
            resourceSelected: null,
            filtersList: {
                workcenter: [],
                status: [],
            },
            filtersSize: 0,
            firstLoading:true,
            resourcesOld:[],
            getHiddenResource: false,
            resourceBackup: [],
            backendResourcesList:[],
            ActiveResourceInputFocus: false,
            loadingResources:false,
        }
    },
    created() {
        this.scroll();
    },
    watch: {
        searchTermResource(v) {
            if(this.getHiddenResource) {
                this.getHiddenResource = false;
                this.resource = [...this.resourceBackup];
                this.resourcesFiltered = [...this.resourceBackup];
            }
        }
    },
    methods: {
        scroll() {
            if(this.firstLoading){
                this.getResources('');
            }
            window.onscroll = () => {
                document.getElementById('resource-filter-input').blur();
                let bottomOfWindow = (window.innerHeight + window.pageYOffset) >= document.documentElement.scrollHeight;
                if (bottomOfWindow && !this.firstLoading && this.resourcesFiltered?.length > 0 && !this.ActiveResourceInputFocus && !this.loadingResources) {
                    this.getResources('moreResources');
                }
            }
        },
        verificaFormatacao(){
            this.resources.forEach((element, index) => {
                const tamanhoStatus = document.getElementsByClassName("badge-status")[index].clientWidth
                if(tamanhoStatus > 230){
                    document.getElementsByClassName("badge-status")[index].setAttribute("style", `max-width:230px; overflow: hidden;
                    text-overflow: ellipsis;color:${element.color}`);
                }
            })
        },
        atualizaShowOverlay(value){
            this.$emit("atualizaShowOverlay", value);
        },
        filterResources(){
            this.$emit("atualizaShowOverlay", true);

            let workcenters = this.filtersList.workcenter
                ? this.filtersList.workcenter.map(item => item.name).join(',')
                : ''

            let status = this.filtersList.status
                ? this.filtersList.status.map(item => item.name).join(',')
                : ''

            this.$http.get(`/api/${this.prefix}/operator/resources?site_guid=${this.currentSite.guid}&workcenters=${workcenters}&status=${status}`)
                .then(res => {
                    this.resources = res.data.response;

                    this.resourcesFiltered = res.data.response;
                    this.$emit("atualizaShowOverlay", false);

                    this.reRenderCarousel++;
                })
        },
        calcFiltersSize(clear = false){
            this.filtersSize = !clear ? this.filtersList.workcenter.length + this.filtersList.status.length : 0;
        },
        clearFilters() {
            this.filtersList.workcenter = [];
            this.filtersList.status = [];
            this.calcFiltersSize(true);
            this.filterResources();
        },
        deleteFilteredItem(type = null, name = null) {
            if(type == 'workcenter') {
                this.filtersList.workcenter.splice(
                    this.filtersList.workcenter.indexOf(
                        this.filtersList.workcenter.find((item) => item.name == name)), 1)
            } else if(type == 'status') {
                this.filtersList.status.splice(
                    this.filtersList.status.indexOf(
                        this.filtersList.status.find((item) => item.name == name)), 1)
            }

            this.calcFiltersSize();
            this.filterResources();
        },
        getResources(status) {
            this.loadingResources = true
            let lastResource = !this.firstLoading ? this.resources.at(-1).id : null;
            this.$emit("atualizaShowOverlay", true);

            this.$http.get(`/api/${this.prefix}/operator/resources?site_guid=${this.currentSite.guid}${this.searchTermResource != '' ? `&filter=${this.searchTermResource}` : ''}${status == 'moreResources' && this.searchTermResource == '' ? `&id=${lastResource}` : ''}`)
                .then((response) => {
                    if(this.searchTermResource == '') {
                        if(response.data.response != []){
                            this.resources = [];
                            this.resources = response.data.response;
                            let totalResources = [...this.resourcesOld,...this.resources]
                            this.resourcesOld = totalResources
                            this.resources = totalResources
                        }
                    } else {
                        if(!this.getHiddenResource){
                            this.resourcesBackup = [...this.resources];
                            this.resourcesFilteredBackup = [...this.resources];

                            this.backendResourcesList = response.data.response;

                            this.resources = [...this.resources, ...this.backendResourcesList].filter((item, index, array) => {
                            return array.findIndex((elem) => {
                                return elem.id === item.id
                            }) === index;})

                            this.resourcesFiltered = [...this.resources, ...this.backendResourcesList].filter((item, index, array) => {
                            return array.findIndex((elem) => {
                                return elem.id === item.id
                            }) === index;})

                        }
                        this.getHiddenResource = this.searchTermResource != '' ? true : false;
                    }
                    this.filterResource();
                    this.firstLoading = false
                    this.loadingResources = false
                    this.$emit("atualizaShowOverlay", false);
                }).then(()=> this.verificaFormatacao())
                .catch(() => {this.$emit("atualizaShowOverlay", false);});

        },
        filterResource(key = null) {
            if(key?.keyCode == 13) return this.getResources('');

            if (this.searchTermResource != '')
                return this.resourcesFiltered = this.resources.filter(el => (el.name.toLowerCase().indexOf(this.searchTermResource.toLowerCase()) != -1))
            return this.resourcesFiltered = this.resources;
        },
        toResourceDetails(resource) {
            this.resourceSelected = resource;
            this.resourceDetails = true;
        },
        backResource() {
            this.resourceSelected = null;
            this.resourceDetails = false;
        }
    }
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';


  .filter-with-size {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600 !important;
    font-size: 14px;
    color: #fff !important;
    width: 100%;
    background-color: #974900 !important;
    border-color: #974900 !important;
    height: 37px;

    &:hover {
        background-color: #974900 !important;
    }

    &:active {
        background-color: #974900 !important;
    }

    &:focus {
        background-color: #974900 !important;
    }

    .circle-filters-size{
        height: 18px;
        width: 18px;
        background: #fff;
        border-radius: 50%;
        margin-left: 8.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        span{
            font-weight: 600;
            font-size: 10px;
            color: #974900;
        }
    }

    .icon-filter-orders  {
        margin-right: 6.5px;

        path {
            fill: #fff;
        }
    }
  }
// alterar
.resource-orders-filtro {
  display: flex;
  align-items: center;
  margin: 10px 0;
  overflow: hidden;

  .limpar-filtros-btn {
    background: #974900;
    border-radius: 5px;
    white-space: nowrap;
    height: fit-content;
    border: 1px solid #974900;
    padding: 3px 14px;
    color: #fff;
    margin-right: 10px;
    font: 600 12px/20px initial;
    &:hover {
      background: #F7F3EF;
      color: #974900;
    }
  }
  .filtros-area {
    display: flex;
    overflow: auto;
    padding-right: 28px;
    margin-right: -28px;
    &::-webkit-scrollbar {
      height: 5px;               /* width of the entire scrollbar */
      border-radius: 50%;
    }

    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background-color: transparent;        /* color of the tracking area */
      margin-right: 28px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: transparent;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
    }

    .slick-slide {
        gap: 15px;
        padding: 0 5px;

        &::-webkit-scrollbar {
            width: 0 !important;
        }
    }

    .filtro-item {
      display: flex;
      font: 600 12px/20px initial;
      align-items: center;
      white-space: nowrap;
      justify-content: center;
      padding: 6px 14px;
      margin: 0 5px;
      background-color: #FFDBC4;
      color: #974900;
      border-radius: 8px;
      svg {
        margin-left: 12px;
      }
      &:hover {
          cursor: pointer;
        }
    }

    .filtro-item:first-child(){
        margin-left: 0px;
    }
  }
}

.dark-layout {
    div ::v-deep {
        .b-overlay {
            .bg-light {
                background-color: $theme-dark-body-bg !important;
            }
        }
    }
}

.resource-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
    color: #4C4541;
}

.total-results {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #998F8A;
}

.btn-add-filter {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600 !important;
    font-size: 14px;
    color: #974900 !important;
    width: 100%;
    background-color: white !important;
    border-color: #974900 !important;
    height: 37px;

    &:hover {
        background-color: #ffede1 !important;
    }

    &:active {
        background-color: #ffede1 !important;
    }

    &:focus {
        background-color: #fff !important;
    }

    .icon-add-filter-orders {
        margin-left: 6.5px;
        margin-top: -2.5px;
    }
}

.badge-status {
    padding: 0.4rem 0.5rem;
    height: 28px;
    background-color: #FFFFFF;
    border-radius: 4px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 15px;
}

.resource-name {
    font-weight: 600;
    font-size: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 15px;
    text-align: left;
}

.btn-resource {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 60px;
    border: none;

}

.row-btn-resource {
    margin-right: -1rem;
    margin-left: -1rem;
}

.div-type-filter-mobile {
    margin-bottom: 2.5rem;

    label {
        font-weight: 400;
        font-size: 14px;
        color: #4C4541;
    }
}

.not-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 150px;
    border: 1px solid #CFC4BE;
    margin-top: 16px;
    border-radius: 5px;
    width: 100%;

    span {
        display: flex;
        align-items: baseline;

        h3 {
            text-align: center;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #998F8A;
        }

        svg {
            margin-right: 16.13px;
        }
    }
}

@media (max-width:480px) {
    .btn-resource {
        flex-direction: column;
        align-items: start;
        height: 84px !important;
        padding: 18px;
    }

    .badge-status {
        padding: 3px 9px;
        height: 18px;
        font-size: 12px;
        max-width: 100% !important;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .resource-name {
        order: 1;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .btn-add-filter {
        font-size: 12px;
        height: 28px;
    }

    .modal-body {
        overflow-y: auto;
    }

    #modal-add-filter {
        padding: 0;

        .modal-header .close {
            left: 0 !important;
            transform: none !important;
            background-color: #f8f8f8 !important;
            top: 0 !important;
            box-shadow: none !important;
            padding: 0.8rem 1.4rem !important
        }
    }

    .div-selected-items {
        display: none !important;
    }

    .div-type-filter {
        display: none !important;
    }

    .div-type-filter-mobile {
        display: block !important;
    }

    .modal-add-text {
        font-weight: 600 !important;
        font-size: 16px !important;
        color: #4C4541 !important;
    }

    .btn-template {
        width: 100%;
    }

    .col-actions {
        margin-top: 3.5rem !important;
    }
}

@media (max-width:991px) {
    .row-btn-resource {
        margin-right: -21px;
        margin-left: -21px;
    }

    .btn-resource {
        border-radius: 0;
    }


    .col-actions {
        margin-top: 10rem;
    }
}
</style>
